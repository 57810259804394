import type { FC } from "react";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import cx from "classnames";
import * as _ from "lodash";
import { Button } from "@material-ui/core";

import { finishMessages } from "../../../constants/custom";
import { Answer, ExerciseQuestionType, QuestionChildren } from "../../../types/main";
import { ExerciseBody } from "../../exercise_body";
import ExerciseBonfireWord from "./word";

import "./styles.scss";

const keyPrefix = "key_";

type Props = {
    exercise: ExerciseQuestionType;
    exerciseNumber: number;
    isGrouped?: boolean;
    onAnswer: (answer: Record<string, Answer>, exerciseId: number, isCorrect: boolean) => void;
    onChange?: () => void;
    userAnswers?: Record<string, Answer>;
};

export const ExerciseBonfire: FC<Props> = ({
    exercise,
    exerciseNumber,
    isGrouped,
    onAnswer,
    onChange,
    userAnswers,
}: Props) => {
    const [children, setChildren] = useState(_.shuffle(exercise.children ?? []));
    useEffect(() => {
        setChildren(_.shuffle(exercise.children ?? []));
        // Reaction only on exercise.children
    }, [exercise.children]);

    const handleAnswer = useCallback(
        (data: { answer: Answer; child: QuestionChildren }) =>
            onAnswer(
                {
                    ...(userAnswers || {}),
                    [keyPrefix + data.child.id]: data.answer,
                },
                exercise.id,
                !!data.answer.correct
            ),
        [exercise.id, onAnswer, userAnswers]
    );

    const total = useMemo(
        () =>
            Math.ceil(
                (Object.keys(userAnswers || {}).reduce((acc, cur, i) => {
                    const score = userAnswers?.[cur].correct ? 1 : 0;
                    return (acc * i + score) / (i + 1);
                }, 0) || 0) * 100
            ),
        [userAnswers]
    );

    const isFinished = children.length === Object.keys(userAnswers ?? {}).length;

    useLayoutEffect(() => onChange?.(), [onChange, isFinished, total, userAnswers]);

    const filteredChildren = useMemo(() => {
        const out = userAnswers
            ? (Object.keys(userAnswers)
                  .map((k) => children.find((c) => k === keyPrefix + c.id))
                  .filter((c) => c !== undefined && userAnswers[keyPrefix + c.id]?.correct) as QuestionChildren[])
            : [];
        const currentQuestion = children.find((c) => !userAnswers?.[keyPrefix + c.id]);
        if (currentQuestion) {
            out.push(currentQuestion);
        }
        return out;
    }, [children, userAnswers]);

    const handleStart = useCallback(() => {
        onAnswer({}, exercise.id, false);
        setChildren(_.shuffle(exercise.children ?? []));
    }, [exercise.children, exercise.id, onAnswer]);

    return (
        <div className="exercise-bonfire">
            <ExerciseBody number={exerciseNumber} text={exercise.text} topic={exercise.topic} />
            <div
                className={cx("exercise-bonfire__children", {
                    "exercise-bonfire__children--correct": isFinished,
                })}
            >
                {filteredChildren?.map((c) => (
                    <ExerciseBonfireWord
                        child={c}
                        key={`child_${c.id}`}
                        onAnswer={handleAnswer}
                        userAnswer={userAnswers?.[keyPrefix + c.id]}
                    />
                ))}
            </div>
            {isFinished && (
                <div className="exercise-bonfire__result">
                    {total < 100 && (
                        <>
                            <h3>Ваши ошибки:</h3>
                            {children.map((c) => {
                                const answer = userAnswers?.[keyPrefix + c.id];
                                if (!answer || answer.correct) {
                                    return null;
                                }
                                return (
                                    <div className="exercise-bonfire__word" key={`result_word_${c.id}`}>
                                        <div className="exercise-bonfire__word-container">
                                            <div className="exercise-bonfire__word-part">
                                                {c.textArray?.[0]}
                                                {answer.text}
                                                {c.textArray?.[1]}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    )}
                    {!isGrouped && (
                        <>
                            <h3>Результат: {total} %</h3>
                            <h3>{finishMessages[`message${Math.floor(total / 10) * 10}`]}</h3>
                        </>
                    )}
                    <div>
                        <Button color="primary" onClick={handleStart} size="large" variant="contained">
                            Начать сначала
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
