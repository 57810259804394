import type { FC } from "react";
import React, { useCallback } from "react";
import { FormControlLabel, Switch } from "@material-ui/core";

import { ExerciseQuestionType, Level } from "../../types/main";

import "./styles.scss";
import easy from "../../images/easy.png";
import hard from "../../images/hard.png";
import medium from "../../images/medium.png";

type Props = {
  currentLevel?: ExerciseQuestionType["level"];
  isGrouped?: boolean;
  levels?: ExerciseQuestionType["level"][];
  onChangeMode?: (isGrouped: boolean) => void;
  onClick?: (level: Level) => void;
};

const LevelsSelector: FC<Props> = ({
  currentLevel,
  isGrouped,
  levels,
  onChangeMode,
  onClick,
}: Props) => {
  const handleChangeMode = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      onChangeMode?.(event.target.checked),
    [onChangeMode]
  );
  const setEasy = useCallback(() => onClick?.("easy"), [onClick]);
  const setHard = useCallback(() => onClick?.("hard"), [onClick]);
  const setMedium = useCallback(() => onClick?.("medium"), [onClick]);

  return (
    <div className="levels">
      {currentLevel === "medium" && (
        <div className="levels__level-label">
          {onChangeMode ? "Упражнение" : "Тест"} второго уровня сложности
        </div>
      )}
      {currentLevel === "hard" && (
        <div className="levels__level-label">
          {onChangeMode ? "Упражнение" : "Тест"} третьего уровня сложности
        </div>
      )}
      {onChangeMode && (
        <div className="levels__group-mode">
          <FormControlLabel
            control={
              <Switch
                checked={!!isGrouped}
                onChange={handleChangeMode}
                size="small"
              />
            }
            label="Групповой режим"
          />
        </div>
      )}
      {levels != undefined && (
        <>
          <h3>Выберите уровень сложности</h3>
          <p>Вы всегда можете поменять уровень в настройках</p>
          <div className="levels__container">
            {(levels.length === 0 || levels.includes("easy")) && (
              <div className="levels__item" onClick={setEasy}>
                <img className="levels__image" src={easy} alt="Лёгкий" />
                <h2>Лёгкий</h2>
              </div>
            )}
            {(levels.length === 0 || levels.includes("medium")) && (
              <div className="levels__item" onClick={setMedium}>
                <img className="levels__image" src={medium} alt="Средний" />
                <h2>Средний</h2>
              </div>
            )}
            {(levels.length === 0 || levels.includes("hard")) && (
              <div className="levels__item" onClick={setHard}>
                <img className="levels__image" src={hard} alt="Сложный" />
                <h2>Сложный</h2>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default LevelsSelector;
