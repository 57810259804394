import type { ChangeEvent, RefObject } from "react";
import { useCallback, useState } from "react";

const showZero = (value: number) => (value < 10 ? `0${value}` : `${value}`);

const toHHMMSS = (value: number) => {
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value - hours * 3600) / 60);
  const seconds = value - hours * 3600 - minutes * 60;
  return (hours > 0 ? hours + ":" : "") + minutes + ":" + showZero(seconds);
};

export const useVideoPlayer = (videoElement: RefObject<HTMLVideoElement>) => {
  const [bufferedPercentageEnd, setBufferedPercentageEnd] = useState(0);
  const [bufferedPercentageStart, setBufferedPercentageStart] = useState(0);
  const [currentTime, setCurrentTime] = useState("0:00");
  const [duration, setDuration] = useState("0:00");
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [speed, setSpeed] = useState(1);

  const togglePlay = useCallback(() => {
    const newPlaying = !playing;
    setPlaying(newPlaying);
    if (videoElement.current) {
      newPlaying ? videoElement.current.play() : videoElement.current.pause();
    }
  }, [playing, videoElement]);

  const handleOnEnded = useCallback(() => {
    setPlaying(false);
    if (videoElement.current) {
      videoElement.current.pause();
    }
  }, [videoElement]);

  const handleOnTimeUpdate = useCallback(() => {
    if (!videoElement.current) {
      return;
    }
    const currentTime = videoElement.current.currentTime || 0;
    const duration = videoElement.current.duration || 0;
    const value = duration ? (currentTime / duration) * 100 : 0;
    setCurrentTime(toHHMMSS(Math.floor(currentTime)));
    setDuration(toHHMMSS(Math.floor(duration)));
    setProgress(value);

    const buffered = videoElement.current.buffered;
    for (let i = 0; i < buffered.length; i++) {
      if (buffered.start(i) <= currentTime && currentTime <= buffered.end(i)) {
        setBufferedPercentageStart(
          duration ? (buffered.start(i) / duration) * 100 : 0
        );
        setBufferedPercentageEnd(
          duration ? (buffered.end(i) / duration) * 100 : 0
        );
        break;
      }
    }
  }, [videoElement]);

  const handleVideoProgress = useCallback(
    (
      event: ChangeEvent<Record<string, unknown>>,
      newValue: number | number[]
    ) => {
      if (videoElement.current) {
        const manualChange = Number(newValue);
        videoElement.current.currentTime =
          (videoElement.current.duration / 100) * manualChange;
        setProgress(manualChange);
      }
    },
    [videoElement]
  );

  const handleVideoSpeed = useCallback(
    (event: any) => {
      if (videoElement.current) {
        const value = Number(event.target.value);
        videoElement.current.playbackRate = value;
        setSpeed(value);
      }
    },
    [videoElement]
  );

  return {
    bufferedPercentageEnd,
    bufferedPercentageStart,
    currentTime,
    duration,
    handleOnEnded,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    playing,
    progress,
    speed,
    togglePlay,
  };
};
