import type { FC } from "react";
import React from "react";
import ImageGallery from "react-image-gallery";
import cx from "classnames";

import type { Content } from "../../types/main";

import pdf from "../../images/pdf.jpeg";

type Props = {
    blur: boolean;
    data: Content;
    onChange?: () => void;
};

export const UnitContent: FC<Props> = ({ blur, data, onChange }: Props) => (
    <div className={cx("unit", { "unit--blur": blur })}>
        <div className="unit__content">
            <ImageGallery
                items={data.images}
                onImageLoad={onChange}
                onSlide={onChange}
                showFullscreenButton={false}
                showPlayButton={false}
            />
            {data.file && (
                <div className="unit__pdf">
                    <div className="unit__pdf-icon">
                        <img src={pdf} alt="" />
                    </div>
                    <div className="unit__pdf-link">
                        <a href={data.file} rel="noreferrer" target="_blank">
                            Скачать схему с объяснением
                        </a>
                    </div>
                </div>
            )}
            {data.additionalFile && (
                <div className="unit__pdf">
                    <div className="unit__pdf-icon">
                        <img src={pdf} alt="" />
                    </div>
                    <div className="unit__pdf-link">
                        <a href={data.additionalFile} rel="noreferrer" target="_blank">
                            Скачать приложение к схеме
                        </a>
                    </div>
                </div>
            )}
        </div>
    </div>
);
