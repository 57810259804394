import type { FC } from "react";
import React, { useLayoutEffect } from "react";
import cx from "classnames";
import { Button } from "@material-ui/core";

import type { ExerciseQuestionType, Level } from "../../types/main";
import LevelsSelector from "../levels";
import LevelsIcon from "../levels/icon";
import { UnitProgress } from "./progress";
import { UnitQuestion } from "./question";
import { UnitResultItem } from "./result_item";
import { UnitTeam } from "./team";

type Props = {
    answers: Record<string, any>;
    blur: boolean;
    сheckButtonTitle: string;
    currentTeam: 1 | 2;
    enableLevelSelect: boolean;
    exerciseColorMode?: "letters" | "words";
    exerciseProps: {
        exercise: ExerciseQuestionType;
        exerciseNumber: number;
        onAnswer: (answer: any, itemId: number, isCorrect?: boolean) => void;
        userAnswers: any;
    };
    exerciseResult?: JSX.Element;
    finish: boolean;
    isGrouped: boolean;
    item: ExerciseQuestionType;
    itemNumber: number;
    itemResult?: {
        answerPercent: number;
        success: boolean;
    };
    itemsLength: number;
    levelIcon: NonNullable<Level>;
    onAnswer: (answer: any, itemId: number, isCorrect?: boolean) => void;
    onBlur: (value: boolean) => void;
    onChange?: (payload?: Record<string, boolean>) => void;
    onChangeMode?: (isGrouped: boolean) => void;
    onCheckAnswerClick?: () => void;
    onCheckButtonClick?: () => void;
    onCompleteExerciseClick?: () => void;
    onLevelIconClick?: () => void;
    onNextClick: () => void;
    onPreviousClick: () => void;
    onRepeatClick?: () => void;
    team1Score: number;
    team2Score: number;
    teamQuestionCount: number;
};

export const UnitUnit: FC<Props> = ({
    answers,
    blur,
    сheckButtonTitle,
    currentTeam,
    enableLevelSelect,
    exerciseColorMode,
    exerciseProps,
    exerciseResult,
    finish,
    isGrouped,
    item,
    itemNumber,
    itemResult,
    itemsLength,
    levelIcon,
    onAnswer,
    onBlur,
    onChange,
    onChangeMode,
    onCheckAnswerClick,
    onCheckButtonClick,
    onCompleteExerciseClick,
    onLevelIconClick,
    onNextClick,
    onPreviousClick,
    onRepeatClick,
    team1Score,
    team2Score,
    teamQuestionCount,
}: Props) => {
    useLayoutEffect(() => onChange?.(), [itemResult, onChange]);

    return (
        <div className={cx("unit", { "unit--blur": blur })}>
            {!enableLevelSelect && (
                <LevelsSelector currentLevel={item.level as Level} isGrouped={isGrouped} onChangeMode={onChangeMode} />
            )}
            <div className="unit__settings">
                <LevelsIcon level={levelIcon} onClick={onLevelIconClick} />
                <UnitProgress length={itemsLength} step={itemNumber} />
            </div>
            {itemResult ? (
                <>
                    <div className="unit__main-screen">
                        <h3>Результат: {itemResult.answerPercent} %</h3>
                    </div>
                    <div className="unit__test-result">
                        <UnitResultItem
                            alwaysDisplay
                            answers={answers}
                            key={`question_result_${item.id}`}
                            number={itemNumber}
                            question={item}
                        />
                    </div>
                </>
            ) : (
                <div className="unit__main-screen">
                    {isGrouped && (
                        <UnitTeam
                            active={currentTeam === 1}
                            finish={finish}
                            maxScore={teamQuestionCount}
                            score={team1Score}
                            team={1}
                        />
                    )}
                    <div className="unit__question">
                        <UnitQuestion
                            answers={answers}
                            exerciseColorMode={exerciseColorMode}
                            exerciseProps={exerciseProps}
                            exerciseResult={exerciseResult}
                            isGrouped={isGrouped}
                            itemNumber={itemNumber}
                            onAnswer={onAnswer}
                            onBlur={onBlur}
                            onChange={onChange}
                            question={item}
                        />
                    </div>
                    {isGrouped && (
                        <UnitTeam
                            active={currentTeam === 2}
                            finish={finish}
                            maxScore={teamQuestionCount}
                            score={team2Score}
                            team={2}
                        />
                    )}
                </div>
            )}
            {exerciseResult && <div className="unit__exercise-result">{exerciseResult}</div>}
            <div className="unit__controls">
                <div className="unit__navigation">
                    <Button
                        color={itemNumber === 1 ? "secondary" : "primary"}
                        disabled={itemNumber === 1}
                        onClick={onPreviousClick}
                        size="large"
                        variant="contained"
                    >
                        &larr; Предыдущий
                    </Button>
                    <Button
                        color="primary"
                        disabled={itemsLength === itemNumber}
                        onClick={onNextClick}
                        size="large"
                        variant="contained"
                    >
                        Cледующий &rarr;
                    </Button>
                    {onCheckAnswerClick && (
                        <Button color="primary" onClick={onCheckAnswerClick} size="large" variant="outlined">
                            Проверить ответ
                        </Button>
                    )}
                </div>
                <div>
                    {onRepeatClick && (
                        <Button color="primary" onClick={onRepeatClick} size="large" variant="contained">
                            Начать сначала
                        </Button>
                    )}
                    {onCheckButtonClick && (
                        <Button color="primary" onClick={onCheckButtonClick} size="large" variant="contained">
                            {сheckButtonTitle}
                        </Button>
                    )}
                    {onCompleteExerciseClick && (
                        <Button color="primary" onClick={onCompleteExerciseClick} size="large" variant="outlined">
                            Завершить
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};
