import React, { FC, useCallback } from "react";
import cx from "classnames";

import { Column } from "../../../types/main";

import monitor from "../../../images/monitor.png";

type Props = {
  column: Column;
  fail: boolean;
  onClick?: (column: number) => void;
  success?: boolean;
};

const ExerciseTvColumn: FC<Props> = ({
  column,
  fail,
  onClick,
  success,
}: Props) => {
  const handleClick: React.DragEventHandler<HTMLDivElement> = useCallback(
    () => onClick?.(column.column),
    [column.column, onClick]
  );
  return (
    <div
      className={cx("exercise-tv__column", {
        "exercise-tv__column--clickable": onClick !== undefined,
        "exercise-tv__column--fail": fail,
        "exercise-tv__column--success": success,
      })}
    >
      <div className="exercise-tv__column-title" onClick={handleClick}>
        <div className="exercise-tv__column-text">
          {column.nameHtml ? (
            <span
              dangerouslySetInnerHTML={{
                __html: column.nameHtml,
              }}
            />
          ) : (
            column.name
          )}
        </div>
      </div>
      <img className="exercise-tv__column-image" src={monitor} alt="Монитор" />
    </div>
  );
};

export default React.memo(ExerciseTvColumn);
