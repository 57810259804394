import type { Answer, Cmi5AUProps, ExerciseQuestionType } from "../../../types/main";
import { ACTIVITIES_CMI_INTERACTION, LAUNCH_MODE_NORMAL, VERB_ANSWERED } from "../constants";
import { noop } from "./noop";

declare let TinCan: any;

export const captureInteractions = ({ cmi, interactionList }: { cmi: Cmi5AUProps["cmi"]; interactionList: any[] }) => {
    if (!cmi || !cmi.isActive() || cmi.getLaunchMode() !== LAUNCH_MODE_NORMAL) {
        return;
    }

    interactionList.forEach((interactionObj) => {
        const stmt = cmi.prepareStatement(VERB_ANSWERED);

        const result: any = {
            duration: TinCan.Utils.convertMillisecondsToISO8601Duration(cmi.getDuration()),
            response:
                interactionObj.userAnswerIds !== undefined
                    ? interactionObj.userAnswerIds.join("[,]")
                    : interactionObj.userAnswer,
            score: interactionObj.scoreObj,
        };
        if (typeof interactionObj.success !== "undefined") {
            result.success = !!interactionObj.success;
        }

        stmt.result = new TinCan.Result(result);

        stmt.object = {
            objectType: "Activity",
            id: cmi.getActivity().id + "/question/" + interactionObj.id,
            definition: {
                type: ACTIVITIES_CMI_INTERACTION,
                interactionType: interactionObj.interactionType,
                name: { "ru-RU": interactionObj.text },
                correctResponsesPattern: [interactionObj.correctAnswers.join("[,]")],
                // correctResponsesPattern: [
                //     "id_1"
                // ],
                // @TODO Support "choices" field 2024.03.26
                // choices: [
                //     {
                //         id: "id_1",
                //         description: {
                //             "ru-RU": "Описание"
                //         }
                //     }
                // ],
            },
        };
        if (interactionObj.description) {
            stmt.object.definition.description = {
                "ru-RU": interactionObj.description,
            };
        }
        if (interactionObj.answers as ExerciseQuestionType["answers"]) {
            stmt.object.definition.choices =
                interactionObj.answers.map((a: Answer) => ({
                    id: a.id,
                    description: {
                        "ru-RU": a.text,
                    },
                })) ?? [];
        }

        // noop is for async request
        cmi.sendStatement(stmt, noop);
    });

    // @TODO Use method for single request
    // const stmts: any[] = [];
    // stmts.push(stmt);
    // cmi.sendStatements(stmts);
};
