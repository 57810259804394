import type { FC } from "react";
import React from "react";
import { Button } from "@material-ui/core";

import type { ExerciseQuestionType, Level } from "../../types/main";
import LevelsSelector from "../levels";
import LevelsIcon from "../levels/icon";
import { UnitProgress } from "./progress";
import { UnitResultItem } from "./result_item";

type Props = {
    answers: Record<string, any>;
    dataByLevel: ExerciseQuestionType[];
    enableLevelSelect: boolean;
    item: ExerciseQuestionType;
    itemNumber: number;
    level?: Level;
    onSubmitTest: () => void;
    testTotal: number;
};

export const UnitResult: FC<Props> = ({
    answers,
    dataByLevel,
    enableLevelSelect,
    item,
    itemNumber,
    level,
    onSubmitTest,
    testTotal,
}: Props) => (
    <div className="unit">
        {!enableLevelSelect && <LevelsSelector currentLevel={item.level as Level} />}
        <div className="unit__settings">
            <LevelsIcon level={level ?? (item.level as Level) ?? "easy"} />
            <UnitProgress length={dataByLevel.length} step={itemNumber} />
        </div>
        <div className="unit__main-screen">
            <h3>Результат: {testTotal} %</h3>
        </div>
        {testTotal < 100 && (
            <div className="unit__test-result">
                <h3>Ваши ошибки:</h3>
                {dataByLevel.map((v, i) => (
                    <UnitResultItem answers={answers} key={`question_result_${v.id}`} number={i + 1} question={v} />
                ))}
            </div>
        )}
        <div className="unit__controls">
            <div className="unit__navigation">
                <Button color="primary" onClick={onSubmitTest} size="large" variant="contained">
                    {enableLevelSelect ? "Завершить" : "Начать сначала"}
                </Button>
            </div>
        </div>
    </div>
);
