import type { FC } from "react";
import React, { memo, useRef } from "react";
import cx from "classnames";
import { SliderProgress } from "./components/slider_progress/slider_progress";
import { SliderSound } from "./components/slider_sound/slider_sound";
import { FullscreenIcon } from "./icons/fullscreen_icon";
import { FullscreenExitIcon } from "./icons/fullscreen_exit_icon";
import { PauseIcon } from "./icons/pause_icon";
import { PictureInPictureExitIcon } from "./icons/picture_in_picture_exit_icon";
import { PictureInPictureIcon } from "./icons/picture_in_picture_icon";
import { PlayIcon } from "./icons/play_icon";
import { VolumeIcon } from "./icons/volume_icon";
import { VolumeOffIcon } from "./icons/volume_off_ocon";
import { useVideoEvents } from "./hooks/use_video_events";
import { useVideoFullscreen } from "./hooks/use_video_fullscreen";
import { useVideoHotkeys } from "./hooks/use_video_hotkeys";
import { useVideoPictureInPicture } from "./hooks/use_video_picture_in_picture";
import { useVideoPlayer } from "./hooks/use_video_player";
import { useVideoSound } from "./hooks/use_video_sound";

import "./styles.scss";

const valueLabelFormat = (value: number) => <>{Math.floor(value * 100)} %</>;

type Props = {
  aspectRatio: string;
  blur: boolean;
  poster?: string;
  quality: string;
  src: string;
  type: string;
};

const VideoFC: FC<Props> = ({
  aspectRatio,
  blur,
  poster,
  quality,
  src,
  type,
}: Props) => {
  const videoElement = useRef<HTMLVideoElement>(null);
  const videoContainer = useRef<HTMLDivElement>(null);
  const {
    bufferedPercentageEnd,
    bufferedPercentageStart,
    currentTime,
    duration,
    handleOnEnded,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    playing,
    progress,
    speed,
    togglePlay,
  } = useVideoPlayer(videoElement);
  const {
    pictureInPicture,
    pictureInPictureEnabled,
    togglePictureInPicture,
  } = useVideoPictureInPicture(videoElement);
  const {
    fullscreen,
    fullscreenEnabled,
    toggleFullscreen,
  } = useVideoFullscreen(videoContainer);
  const {
    handleCommittedVolumeLevel,
    handleVolumeLevel,
    muted,
    toggleMute,
    volumeLevel,
  } = useVideoSound(videoElement);
  const { canplay, loadedMetaData } = useVideoEvents(videoElement);
  useVideoHotkeys({ togglePlay });

  return (
    <div className={cx("unit", { "unit--blur": blur })}>
      <div className="unit__video">
        <div
          className={cx("unit__video-container", {
            "unit__video-container--pause": !playing,
          })}
          ref={videoContainer}
          style={{ aspectRatio }}
        >
          <video
            className="unit__video-screen"
            controls={false}
            onEnded={handleOnEnded}
            onLoadedMetadata={handleOnTimeUpdate}
            onTimeUpdate={handleOnTimeUpdate}
            poster={poster}
            preload="metadata"
            ref={videoElement}
          >
            <source src={src} type={type} />
          </video>
          <div className="unit__video-controls-background"></div>
          <div className="unit__video-play" onClick={togglePlay}></div>
          <div className="unit__video-controls">
            <div className="unit__video-progress">
              <div className="unit__video-progress-slider">
                <SliderProgress
                  max={100}
                  min={0}
                  onChange={handleVideoProgress}
                  value={progress}
                  valueLabelFormat={`${currentTime}`}
                />
              </div>
              <div
                className="unit__video-progress-cache"
                style={{
                  marginLeft: `${bufferedPercentageStart}%`,
                  width: `${bufferedPercentageEnd - bufferedPercentageStart}%`,
                }}
              ></div>
            </div>
            <div className="unit__video-buttons-container">
              <div className="unit__video-buttons-left">
                <div className="unit__video-button-item" onClick={togglePlay}>
                  {playing ? <PauseIcon /> : <PlayIcon />}
                </div>
                <div className="unit__video-button-item" onClick={toggleMute}>
                  {muted ? <VolumeIcon /> : <VolumeOffIcon />}
                </div>
                <div className="unit__video-volume">
                  <SliderSound
                    max={1}
                    min={0}
                    onChange={handleVolumeLevel}
                    onChangeCommitted={handleCommittedVolumeLevel}
                    step={0.05}
                    value={volumeLevel}
                    valueLabelFormat={valueLabelFormat}
                  />
                </div>
                {loadedMetaData && (
                  <div className="unit__video-text-item">
                    {currentTime} / {duration}
                  </div>
                )}
              </div>
              <div className="unit__video-buttons-right">
                <div className="unit__video-text-item">{quality}</div>
                <select
                  className="unit__video-velocity"
                  value={speed}
                  onChange={handleVideoSpeed}
                >
                  <option value="0.25">0,25x</option>
                  <option value="0.5">0,50x</option>
                  <option value="0.75">0,75x</option>
                  <option value="1">1x</option>
                  <option value="1.25">1,25x</option>
                  <option value="1.5">1,50x</option>
                  <option value="1.75">1,75x</option>
                  <option value="2">2x</option>
                </select>
                {pictureInPictureEnabled && !fullscreen && (
                  <div
                    className="unit__video-button-item"
                    onClick={canplay ? togglePictureInPicture : undefined}
                  >
                    {pictureInPicture ? (
                      <PictureInPictureExitIcon />
                    ) : (
                      <PictureInPictureIcon />
                    )}
                  </div>
                )}
                {fullscreenEnabled && (
                  <div
                    className="unit__video-button-item"
                    onClick={pictureInPicture ? undefined : toggleFullscreen}
                  >
                    {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Video = memo(VideoFC);
