import type { FC } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import cx from "classnames";

import { Answer, ExerciseQuestionType } from "../../../types/main";
import { ExerciseBody } from "../../exercise_body";
import ExerciseColorButton from "./button";
import ExerciseColorLetter from "./letter";

import "./styles.scss";

type Props = {
    exercise: ExerciseQuestionType;
    exerciseNumber: number;
    mode?: "letters" | "words";
    onAnswer: (
        answer: Record<
            string,
            {
                color: string;
                id: number;
                positionFrom: number;
                positionTo: number;
            } | null
        >,
        exerciseId: number
    ) => void;
    showAnswers?: boolean;
    userAnswers?: Record<
        string,
        {
            color: string;
            id: number;
            positionFrom: number;
            positionTo: number;
        } | null
    >;
};

export const ExerciseColor: FC<Props> = ({
    exercise,
    exerciseNumber,
    mode = "words",
    onAnswer,
    showAnswers,
    userAnswers,
}: Props) => {
    const [selecting, setSelecting] = useState(false);
    useEffect(() => {
        const mouseDownListener = () => setSelecting(true);
        window.addEventListener("mousedown", mouseDownListener);
        return () => window.removeEventListener("mousedown", mouseDownListener);
    }, []);
    useEffect(() => {
        const mouseUpListener = () => setSelecting(false);
        window.addEventListener("mouseup", mouseUpListener);
        return () => window.removeEventListener("mouseup", mouseUpListener);
    }, []);

    const [exerciseAnswer, setExerciseAnswer] = useState<Answer | undefined>(exercise.answers?.[0]);
    useEffect(() => {
        setExerciseAnswer(exercise.answers?.[0]);
    }, [exercise.answers]);
    const handleAnswer = useCallback(
        (positionFrom: number, positionTo: number, skipSelectingCheck?: boolean) =>
            !showAnswers &&
            (selecting || skipSelectingCheck) &&
            onAnswer(
                {
                    ...(userAnswers || {}),
                    [`position_${positionFrom}_${positionTo}`]: exerciseAnswer?.color
                        ? {
                              color: exerciseAnswer.color,
                              id: exerciseAnswer.id,
                              positionFrom,
                              positionTo,
                          }
                        : null,
                },
                exercise.id
            ),
        [exercise.id, exerciseAnswer, onAnswer, selecting, showAnswers, userAnswers]
    );
    const handleColorSelect = useCallback((a: Answer) => setExerciseAnswer(a), []);
    const handleEraserSelect = useCallback(() => setExerciseAnswer(undefined), []);

    const question = useMemo(() => {
        if (mode === "letters") {
            const letters = exercise.question?.split("") || [];
            return letters.map((l, i) => (
                <ExerciseColorLetter
                    color={userAnswers?.[`position_${i}_${i}`]?.color}
                    key={`letter_${i}`}
                    onSelect={handleAnswer}
                    positionFrom={i}
                    positionTo={i}
                >
                    {l}
                </ExerciseColorLetter>
            ));
        }
        const regExp = /([$-/:-?{-~!"^«–»—_`\[\]\s\n]*)([A-zА-яЁё0-9\-]+)([$-/:-?{-~!"^«–»—_`\[\]\s\n]*)/g;
        const matchAll = Array.from(exercise.question?.matchAll(regExp) || []);
        const out: JSX.Element[] = [];
        matchAll.forEach((v, i) => {
            const positionFrom = (v["index"] ?? 0) + v[1].length;
            const positionTo = positionFrom + v[2].length;
            out.push(
                <>
                    {v[1].split("\n").map((sv, si) => (
                        <>
                            {si > 0 && <div style={{ marginBottom: "8px" }} />}
                            {sv}
                        </>
                    ))}
                </>
            );
            out.push(
                <ExerciseColorLetter
                    color={userAnswers?.[`position_${positionFrom}_${positionTo}`]?.color}
                    key={`letter_${i}`}
                    onSelect={handleAnswer}
                    positionFrom={positionFrom}
                    positionTo={positionTo}
                >
                    {v[2]}
                </ExerciseColorLetter>
            );
            out.push(
                <>
                    {v[3].split("\n").map((sv, si) => (
                        <>
                            {si > 0 && <div style={{ marginBottom: "8px" }} />}
                            {sv}
                        </>
                    ))}
                </>
            );
        });
        return out;
    }, [exercise.question, handleAnswer, mode, userAnswers]);

    const questionWithAnswers = useMemo(() => {
        const letters = exercise.question?.split("") || [];
        return letters.map((l, i) => {
            const color = exercise.answers?.find((a) => a.words?.find((w) => i >= w[0] && i <= w[1]))?.color;
            return (
                <span
                    className="exercise-color__letter"
                    key={`letter_${i}`}
                    style={color ? { background: color } : undefined}
                >
                    {l === "\n" ? <div style={{ marginBottom: "8px" }} /> : l}
                </span>
            );
        });
    }, [exercise.answers, exercise.question]);

    // по словам, по буквам, по знакам припенания
    return (
        <div className="exercise-color">
            <ExerciseBody number={exerciseNumber} text={exercise.text} topic={exercise.topic} />
            <div className="exercise-color__colors">
                {exercise.answers?.map((a) => (
                    <ExerciseColorButton
                        answer={a}
                        key={`answer_color_${a.id}`}
                        onClick={handleColorSelect}
                        selected={exerciseAnswer?.color === a.color}
                    />
                ))}
                <div
                    className={cx("exercise-color__color-container", {
                        "exercise-color__color-container--selected": !exerciseAnswer,
                    })}
                >
                    <div className="exercise-color__color exercise-color__color--eraser" onClick={handleEraserSelect} />
                </div>
            </div>
            <div className="exercise-color__question">{question}</div>
            {showAnswers && (
                <>
                    <h3>Правильный ответ:</h3>
                    <div className="exercise-color__question">{questionWithAnswers}</div>
                </>
            )}
        </div>
    );
};
