import type { PropsWithChildren, ReactElement } from "react";
import React from "react";
import type { SliderTypeMap } from "@material-ui/core";
import type { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Slider as MaterialSlider, Tooltip } from "@material-ui/core";

type ValueLabelComponentProps = PropsWithChildren<{ open: any; value: any }>;
function ValueLabelComponent(props: ValueLabelComponentProps) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children as ReactElement}
    </Tooltip>
  );
}

export const Slider: OverridableComponent<SliderTypeMap> = (props: any) => (
  <MaterialSlider ValueLabelComponent={ValueLabelComponent} {...props} />
);
