import type { FC } from "react";
import React, { PropsWithChildren, useCallback } from "react";

type Props = {
  color?: string;
  onSelect: (
    positionFrom: number,
    positionTo: number,
    skipSelectingCheck?: boolean
  ) => void;
  positionFrom: number;
  positionTo: number;
};

const ExerciseColorLetter: FC<PropsWithChildren<Props>> = ({
  children,
  color,
  onSelect,
  positionFrom,
  positionTo,
}: PropsWithChildren<Props>) => {
  const handleMouseDown = useCallback(
    () => onSelect(positionFrom, positionTo, true),
    [onSelect, positionFrom, positionTo]
  );
  const handleMouseOver = useCallback(
    () => onSelect(positionFrom, positionTo),
    [onSelect, positionFrom, positionTo]
  );

  return (
    <span
      className="exercise-color__letter"
      onMouseDown={handleMouseDown}
      onMouseOver={handleMouseOver}
      style={{ background: color }}
    >
      {children}
    </span>
  );
};

export default React.memo(ExerciseColorLetter);
