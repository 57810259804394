import type { FC } from "react";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import * as _ from "lodash";
import { Button, Modal, TextField } from "@material-ui/core";

import { failMessages, finishMessages, modalMessageTimeout, successMessages } from "../../../constants/custom";
import { ExerciseQuestionType, Level } from "../../../types/main";
import { ExerciseBody } from "../../exercise_body";

import "./styles.scss";

type LevelSettings = {
    level: Level;
    score: number;
    text: string;
};

const levels: LevelSettings[] = [
    {
        level: "easy",
        score: 5,
        text: "5 рублей",
    },
    {
        level: "medium",
        score: 10,
        text: "10 рублей",
    },
];

type Props = {
    exercise: ExerciseQuestionType;
    exerciseNumber: number;
    onAnswer: (answer: Record<string, string>, exerciseId: number) => void;
    onBlur: (value: boolean) => void;
    onChange?: () => void;
    userAnswers?: Record<string, string>;
};

export const ExerciseRubles: FC<Props> = ({
    exercise,
    exerciseNumber,
    onAnswer,
    onBlur,
    onChange,
    userAnswers,
}: Props) => {
    const [answerMessage, setAnswerMessage] = useState<string | null>(null);
    const [currentAnswer, setCurrentAnswer] = useState("");
    const [currentItemNumber, setCurrentItemNumber] = useState(0);
    const [currentLevel, setCurrentLevel] = useState<LevelSettings | null>(null);
    const [lastItemNumber, setLastItemNumber] = useState(-1);
    const [rubles, setRubles] = useState(100);
    const [successCount, setSuccessCount] = useState(0);

    const total = useMemo(() => (currentItemNumber > 0 ? Math.ceil((successCount / currentItemNumber) * 100) : 0), [
        currentItemNumber,
        successCount,
    ]);

    const currentQuestion = useMemo(
        () => exercise.children?.[currentItemNumber]?.children?.find((v) => v.level === currentLevel?.level),
        [currentItemNumber, currentLevel, exercise]
    );

    const handleStart = useCallback(() => {
        onAnswer({}, exercise.id);
        setCurrentAnswer("");
        setCurrentItemNumber(0);
        setCurrentLevel(null);
        setLastItemNumber(-1);
        setRubles(100);
        setSuccessCount(0);
    }, [exercise.id, onAnswer]);

    const handleAnswer = useCallback(() => {
        if (currentQuestion) {
            onAnswer(
                {
                    ...(userAnswers || {}),
                    [currentQuestion.id.toString()]: currentAnswer,
                },
                exercise.id
            );
            if (currentLevel) {
                const isSuccess =
                    currentQuestion.answers.find((a) => a.text.toLowerCase() === currentAnswer.trim().toLowerCase()) !=
                    undefined;
                setRubles((prevState) => (isSuccess ? prevState + currentLevel.score : prevState - currentLevel.score));
                if (isSuccess) {
                    setSuccessCount((prevState) => prevState + 1);
                }
                const messages = isSuccess ? successMessages : failMessages;
                setAnswerMessage(_.shuffle(messages)[0]);
                onBlur(true);
                setTimeout(() => setLastItemNumber(currentItemNumber), modalMessageTimeout);
                return;
            }
        }
        setLastItemNumber(currentItemNumber);
    }, [currentAnswer, currentItemNumber, currentLevel, currentQuestion, exercise.id, onAnswer, onBlur, userAnswers]);

    const handleAnswerChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => setCurrentAnswer(event.target.value),
        []
    );

    const handleClose = useCallback(() => {
        setAnswerMessage(null);
        onBlur(false);
    }, [onBlur]);

    useEffect(() => {
        if (currentItemNumber === lastItemNumber) {
            setAnswerMessage(null);
            onBlur(false);
            setCurrentAnswer("");
            setCurrentItemNumber((prevState) => prevState + 1);
            setCurrentLevel(null);
        }
    }, [currentItemNumber, lastItemNumber]);

    useLayoutEffect(() => onChange?.(), [currentItemNumber, currentLevel, onChange, userAnswers]);

    const currentUserAnswer = useMemo(
        () => (currentQuestion ? userAnswers?.[currentQuestion.id.toString()] : undefined),
        [currentQuestion, userAnswers]
    );

    return (
        <div className="exercise-rubles">
            <ExerciseBody number={exerciseNumber} text={exercise.text} topic={exercise.topic} />
            {lastItemNumber === -1 && currentLevel == null && userAnswers && Object.keys(userAnswers).length > 0 ? (
                <div>
                    <Button color="primary" onClick={handleStart} size="large" variant="outlined">
                        Начать
                    </Button>
                </div>
            ) : (
                <>
                    <p>
                        Текущий баланс: <strong>{rubles} рублей</strong>.
                    </p>
                    {currentLevel && (
                        <>
                            <p>
                                Выбранная ставка: <strong>{currentLevel.text}</strong>.
                            </p>
                            <div
                                className="exercise-rubles__question"
                                dangerouslySetInnerHTML={{
                                    __html: currentQuestion?.text || "",
                                }}
                            />
                            <div className="exercise-rubles__answer-form">
                                <div>Ваш ответ:</div>
                                <div>
                                    {currentUserAnswer == undefined ? (
                                        <TextField onChange={handleAnswerChange} value={currentAnswer} />
                                    ) : (
                                        <TextField disabled value={currentUserAnswer} />
                                    )}
                                </div>
                                <div>
                                    <Button
                                        color="primary"
                                        onClick={currentUserAnswer == undefined ? handleAnswer : undefined}
                                        size="large"
                                        variant="outlined"
                                    >
                                        Проверить
                                    </Button>
                                </div>
                            </div>
                            <Modal className="modal-container" open={answerMessage != null} onClose={handleClose}>
                                <div className="modal">{answerMessage}</div>
                            </Modal>
                        </>
                    )}
                    {!currentLevel && exercise.children?.[currentItemNumber] && (
                        <>
                            <div
                                className="exercise-rubles__subject"
                                dangerouslySetInnerHTML={{
                                    __html: exercise.children?.[currentItemNumber].text || "",
                                }}
                            />
                            <div className="exercise-rubles__level-select">
                                {levels.map((v) => (
                                    <Button
                                        color="primary"
                                        key={`rubles_level_${v.level}`}
                                        onClick={() => setCurrentLevel(v)}
                                        size="large"
                                        variant="outlined"
                                    >
                                        {v.text}
                                    </Button>
                                ))}
                            </div>
                        </>
                    )}
                    {!exercise.children?.[currentItemNumber] && (
                        <div className="exercise-rubles__result">
                            <h3>Результат: {total} %</h3>
                            <h3>{finishMessages[`message${Math.floor(total / 10) * 10}`]}</h3>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
