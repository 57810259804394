import type { FC } from "react";
import React, { useCallback } from "react";

import { Answer } from "../../../types/main";

type Props = {
  answer: Answer;
  onClick: (answer: Answer) => void;
};

const ExerciseBonfireAnswer: FC<Props> = ({ answer, onClick }: Props) => {
  const handleClick = useCallback(() => {
    onClick(answer);
  }, [answer, onClick]);

  return (
    <div className="exercise-bonfire__answer" onClick={handleClick}>
      {answer.text}
    </div>
  );
};

export default React.memo(ExerciseBonfireAnswer);
