import type { FC } from "react";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import cx from "classnames";
import * as _ from "lodash";
import { Button, Modal } from "@material-ui/core";

import { failMessages, finishMessages, modalMessageTimeout, successMessages } from "../../../constants/custom";
import { ExerciseQuestionType, QuestionChildren } from "../../../types/main";
import { ExerciseBody } from "../../exercise_body";
import ExerciseOrangesAnswer from "./answer";
import ExerciseOrangesWord from "./word";

import "./styles.scss";

const keyPrefix = "key_";

type Props = {
    exercise: ExerciseQuestionType;
    exerciseNumber: number;
    isGrouped?: boolean;
    onAnswer: (answer: Record<string, string>, exerciseId: number, isCorrect: boolean) => void;
    onBlur: (value: boolean) => void;
    onChange?: () => void;
    userAnswers?: Record<string, string>;
};

export const ExerciseOranges: FC<Props> = ({
    exercise,
    exerciseNumber,
    isGrouped,
    onAnswer,
    onBlur,
    onChange,
    userAnswers,
}: Props) => {
    const [answerMessage, setAnswerMessage] = useState<string | null>(null);
    const [lastChildAnswered, setLastChildAnswered] = useState<number>();
    const [children, setChildren] = useState(_.shuffle(exercise.children ?? []));
    const [currenChild, setCurrenChild] = useState(children.find((c) => userAnswers?.[keyPrefix + c.id] === undefined));

    useEffect(() => {
        const ch = _.shuffle(exercise.children ?? []);
        setChildren(ch);
        setCurrenChild(ch.find((c) => userAnswers?.[keyPrefix + c.id] === undefined));
        // Reaction only on exercise.children
    }, [exercise.children]);

    const total = useMemo(
        () =>
            Math.ceil(
                (children.reduce((acc, cur, i) => {
                    const score = userAnswers && cur.answers[0]?.text === userAnswers[keyPrefix + cur.id] ? 1 : 0;
                    return (acc * i + score) / (i + 1);
                }, 0) || 0) * 100
            ),
        [children, userAnswers]
    );

    const handleAnswer = useCallback(
        (data: { answer: string; child: QuestionChildren }) => {
            const isCorrect = data.child.answers[0]?.text === data.answer;
            onAnswer(
                {
                    ...(userAnswers || {}),
                    [keyPrefix + data.child.id]: data.answer,
                },
                exercise.id,
                isCorrect
            );
            const messages = isCorrect ? successMessages : failMessages;
            setAnswerMessage(_.shuffle(messages)[0]);
            onBlur(true);
            setTimeout(() => setLastChildAnswered(data.child.id), modalMessageTimeout);
        },
        [exercise.id, onAnswer, onBlur, userAnswers]
    );

    const handleClose = useCallback(() => {
        setAnswerMessage(null);
        onBlur(false);
    }, [onBlur]);

    useEffect(() => {
        setAnswerMessage(null);
        onBlur(false);
        setCurrenChild(children.find((c) => userAnswers?.[keyPrefix + c.id] === undefined));
        // Reaction only on lastChildAnswered
    }, [lastChildAnswered]);

    useLayoutEffect(() => onChange?.(), [currenChild, onChange, total]);

    const answers = useMemo(
        () =>
            _.uniq(children.reduce((a: string[], c) => [...a, ...c.answers.map((v) => v.text)], []) || []).sort(
                function (a, b) {
                    if (a > b) {
                        return 1;
                    }
                    if (a < b) {
                        return -1;
                    }
                    return 0;
                }
            ),
        [children]
    );

    const handleClick = useCallback(
        (text: string) => {
            if (currenChild) {
                handleAnswer({
                    answer: text,
                    child: currenChild,
                });
            }
        },
        [currenChild, handleAnswer]
    );

    const handleStart = useCallback(() => {
        onAnswer({}, exercise.id, false);
        const ch = _.shuffle(exercise.children ?? []);
        setChildren(ch);
        setCurrenChild(ch[0]);
        setLastChildAnswered(undefined);
    }, [exercise.children, exercise.id, onAnswer]);

    return (
        <div className="exercise-oranges">
            <ExerciseBody number={exerciseNumber} text={exercise.text} topic={exercise.topic} />
            {currenChild ? (
                <>
                    <div className="exercise-oranges__words">
                        <ExerciseOrangesWord
                            child={currenChild}
                            key={`child_${currenChild.id}`}
                            onDrop={handleAnswer}
                            userAnswer={userAnswers?.[keyPrefix + currenChild.id]}
                        />
                        <Modal className="modal-container" open={answerMessage != null} onClose={handleClose}>
                            <div className="modal">{answerMessage}</div>
                        </Modal>
                    </div>
                    <div className="exercise-oranges__answers">
                        {answers.map((a) => (
                            <ExerciseOrangesAnswer key={`answer_${a}`} onClick={handleClick} text={a} />
                        ))}
                    </div>
                </>
            ) : (
                <div className="exercise-oranges__result">
                    <h3>{children.length === 1 ? "Ваш ответ:" : "Ваши ответы:"}</h3>
                    <div className="exercise-oranges__result-answers">
                        {userAnswers &&
                            Object.keys(userAnswers).map((key, i) => {
                                const c = children.find((v) => keyPrefix + v.id === key);
                                return (
                                    c && (
                                        <div className="exercise-oranges__result-child" key={`oranges_answer_${c.id}`}>
                                            <div className="exercise-oranges__result-text">
                                                {children.length > 1 ? `${i + 1}. ` : ""}
                                                {c.textArray?.[0]}
                                            </div>
                                            <div
                                                className={cx("exercise-oranges__result-answer", {
                                                    "exercise-oranges__result-answer--correct":
                                                        c.answers[0]?.text === userAnswers[key],
                                                })}
                                            >
                                                {userAnswers[key]}
                                            </div>
                                            <div className="exercise-oranges__result-text">{c.textArray?.[1]}</div>
                                        </div>
                                    )
                                );
                            })}
                    </div>
                    {!isGrouped && (
                        <>
                            <h3>Результат: {total} %</h3>
                            <h3>{finishMessages[`message${Math.floor(total / 10) * 10}`]}</h3>
                        </>
                    )}
                    <div>
                        <Button color="primary" onClick={handleStart} size="large" variant="contained">
                            Начать сначала
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
