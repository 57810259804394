import type { RefObject } from "react";
import { useCallback, useEffect, useState } from "react";

export const useVideoPictureInPicture = (
  videoElement: RefObject<HTMLVideoElement>
) => {
  const [pictureInPicture, setPictureInPicture] = useState(false);

  const togglePictureInPicture = useCallback(() => {
    if (!videoElement.current) {
      return;
    }

    const requestPictureInPicture = () => {
      if (videoElement.current) {
        if ((videoElement.current as any).requestPictureInPicture) {
          (videoElement.current as any).requestPictureInPicture();
        } else if ((videoElement.current as any).webkitSetPresentationMode) {
          (videoElement.current as any).webkitSetPresentationMode(
            (videoElement.current as any).webkitPresentationMode ===
              "picture-in-picture"
              ? "inline"
              : "picture-in-picture"
          );
        }
      }
    };

    if (pictureInPicture) {
      (document as any).exitPictureInPicture();
    } else {
      requestPictureInPicture();
    }
  }, [pictureInPicture, videoElement]);

  useEffect(() => {
    const handleEnterPictureInPicture = () => setPictureInPicture(true);
    const handleLeavePictureInPicture = () => setPictureInPicture(false);
    videoElement.current?.addEventListener(
      "enterpictureinpicture",
      handleEnterPictureInPicture
    );
    videoElement.current?.addEventListener(
      "leavepictureinpicture",
      handleLeavePictureInPicture
    );
    return () => {
      videoElement.current?.removeEventListener(
        "enterpictureinpicture",
        handleEnterPictureInPicture
      );
      videoElement.current?.removeEventListener(
        "leavepictureinpicture",
        handleLeavePictureInPicture
      );
    };
  }, [videoElement]);

  return {
    pictureInPicture,
    pictureInPictureEnabled: (document as any)
      .pictureInPictureEnabled as boolean,
    togglePictureInPicture,
  };
};
