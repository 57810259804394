import type { RefObject } from "react";
import { useEffect, useState } from "react";

export const useVideoEvents = (videoElement: RefObject<HTMLVideoElement>) => {
  // Using "canplay" can fix fail of execute 'requestPictureInPicture'
  // (in togglePictureInPicture of useVideoPictureInPicture) when video is not loaded yet
  // or the video element does not have a video track or it has not detected a video track yet.
  const [canplay, setCanplay] = useState(false);
  const [loadedMetaData, setLoadedMetaData] = useState(false);

  useEffect(() => {
    const handleLoadedMetaData = () => setLoadedMetaData(true);
    const handleCanplay = () => setCanplay(true);
    videoElement.current?.addEventListener(
      "loadedmetadata",
      handleLoadedMetaData
    );
    videoElement.current?.addEventListener("canplay", handleCanplay);
    return () => {
      videoElement.current?.removeEventListener(
        "loadedmetadata",
        handleLoadedMetaData
      );
      videoElement.current?.removeEventListener("canplay", handleCanplay);
    };
  }, [videoElement]);

  return {
    canplay,
    loadedMetaData,
  };
};
