import React, { FC } from "react";
import cx from "classnames";

import { Answer } from "../../../types/main";

type Props = {
  answers: Answer[];
  inverse?: boolean;
  label: string;
};

const ExerciseTetrisAnswers: FC<Props> = ({
  answers,
  inverse,
  label,
}: Props) => (
  <div className="exercise-tetris__user-answers-container">
    <div className="exercise-tetris__user-answers-label">{label}</div>
    {answers.map((a) => (
      <div
        className={cx("exercise-tetris__user-answer", {
          "exercise-tetris__user-answer--correct": inverse
            ? !a.correct
            : a.correct,
        })}
        key={`user_answers_${a.id}`}
      >
        {a.description || a.text}
      </div>
    ))}
  </div>
);

export default React.memo(ExerciseTetrisAnswers);
