import type { Content } from "../types/main";

export const contentData: Content = {
  additionalFile:
    "https://self-test.ru/upload/questions/208759_aa670d556236.pdf",
  file: "https://self-test.ru/upload/questions/208759_aa670d556236.pdf",
  images: [
    {
      original: "https://self-test.ru/upload/questions/208751_f119212ff4fe.png",
      thumbnail:
        "https://self-test.ru/upload/questions/208751_f119212ff4fe.png",
    },
    {
      original: "https://self-test.ru/upload/questions/208752_81456a214965.png",
      thumbnail:
        "https://self-test.ru/upload/questions/208752_81456a214965.png",
    },
    {
      original: "https://self-test.ru/upload/questions/208753_8077d0d7f502.png",
      thumbnail:
        "https://self-test.ru/upload/questions/208753_8077d0d7f502.png",
    },
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ],
};
