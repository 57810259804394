import type { FC } from "react";
import React, { useCallback } from "react";
import cx from "classnames";

import { Answer } from "../../../types/main";

type Props = {
  answer: Answer;
  onClick: (answer: Answer) => void;
  selected: boolean;
};

const ExerciseColorButton: FC<Props> = (props: Props) => {
  const handleClick = useCallback(
    () => props.answer.color && props.onClick(props.answer),
    [props.answer, props.onClick]
  );

  return props.answer.color ? (
    <div
      className={cx("exercise-color__color-container", {
        "exercise-color__color-container--selected": props.selected,
      })}
    >
      <div
        className="exercise-color__color"
        onClick={handleClick}
        style={{ background: props.answer.color }}
      />
    </div>
  ) : null;
};

export default React.memo(ExerciseColorButton);
