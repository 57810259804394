import type { FC } from "react";
import React, { memo } from "react";
import cx from "classnames";

type Props = {
    active: boolean;
    finish: boolean;
    maxScore: number;
    score: number;
    team: number;
};

const UnitTeamFC: FC<Props> = ({ active, finish, maxScore, score, team }: Props) => (
    <div
        className={cx("unit__team", `unit__team--${team}`, {
            "unit__team--active": active || finish,
        })}
    >
        <div className="unit__team-container">
            <div
                className="unit__team-progress"
                style={{
                    height: `${(score / maxScore) * 100}%`,
                }}
            />
        </div>
        <div className="unit__team-score">
            <div className="unit__team-score-background" />
            {active && !finish ? (
                <div className="unit__team-move">Ваш ход</div>
            ) : (
                <div className="unit__team-score-name">{score}</div>
            )}
        </div>
    </div>
);

export const UnitTeam = memo(UnitTeamFC);
