import type { FC } from "react";
import React, { useCallback } from "react";

import { Answer, QuestionChildren } from "../../../types/main";
import ExerciseBonfireAnswer from "./answer";

type Props = {
  child: QuestionChildren;
  onAnswer: (data: { answer: Answer; child: QuestionChildren }) => void;
  userAnswer?: Answer;
};

const ExerciseBonfireWord: FC<Props> = ({
  child,
  onAnswer,
  userAnswer,
}: Props) => {
  const handleClick = useCallback(
    (answer: Answer) => {
      onAnswer({ answer, child });
    },
    [child, onAnswer]
  );
  return (
    <div className="exercise-bonfire__word">
      <div className="exercise-bonfire__word-container">
        {userAnswer ? (
          <div className="exercise-bonfire__word-part">
            {child.textArray?.[0]}
            {userAnswer.text}
            {child.textArray?.[1]}
          </div>
        ) : (
          <>
            <div className="exercise-bonfire__word-part">
              {child.textArray?.[0]}
            </div>
            <div className="exercise-bonfire__answers">
              {child.answers.map((a) => (
                <ExerciseBonfireAnswer
                  answer={a}
                  key={`answer_${a.id}`}
                  onClick={handleClick}
                />
              ))}
            </div>
            <div className="exercise-bonfire__word-part">
              {child.textArray?.[1]}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(ExerciseBonfireWord);
