import type { FC } from "react";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import cx from "classnames";
import * as _ from "lodash";
import { Button, Modal } from "@material-ui/core";

import { failMessages, finishMessages, modalMessageTimeout, successMessages } from "../../../constants/custom";
import { ExerciseQuestionType } from "../../../types/main";
import { ExerciseBody } from "../../exercise_body";
import ExerciseTvColumn from "./column";

import "./styles.scss";

type Props = {
    exercise: ExerciseQuestionType;
    exerciseNumber: number;
    isGrouped?: boolean;
    onAnswer: (answer: Array<{ answerId: number; column: number }>, exerciseId: number, isCorrect: boolean) => void;
    onBlur: (value: boolean) => void;
    onChange?: () => void;
    userAnswers?: Array<{ answerId: number; column: number }>;
};

export const ExerciseTv: FC<Props> = ({
    exercise,
    exerciseNumber,
    isGrouped,
    onAnswer,
    onBlur,
    onChange,
    userAnswers,
}: Props) => {
    const [answerMessage, setAnswerMessage] = useState<string | null>(null);

    const [currentAnswerNumber, setCurrentAnswerNumber] = useState<number>(0);
    useEffect(() => {
        let value = 0;
        exercise.answers?.find((v, i) => {
            if (!userAnswers?.find((ua) => ua.answerId === v.id)) {
                return true;
            }
            value = i + 1;
            return false;
        });
        setCurrentAnswerNumber(value);
        // Skip other dependencies
    }, [exercise.answers]);

    const [lastAnswerNumber, setLastAnswerNumber] = useState<number>();

    const handleAnswer = useCallback(
        (column: number) => {
            const currentAnswer = exercise.answers?.[currentAnswerNumber];
            if (currentAnswer) {
                const isCorrect = currentAnswer.column === column;
                onAnswer(
                    [
                        ...(userAnswers || []).filter((v) => v.answerId !== currentAnswer.id),
                        ...[
                            {
                                answerId: currentAnswer.id,
                                column,
                            },
                        ],
                    ],
                    exercise.id,
                    isCorrect
                );
                const messages = isCorrect ? successMessages : failMessages;
                setAnswerMessage(_.shuffle(messages)[0]);
                onBlur(true);
                setTimeout(() => setLastAnswerNumber(currentAnswerNumber), modalMessageTimeout);
            }
        },
        [currentAnswerNumber, exercise.answers, exercise.id, onAnswer, onBlur, userAnswers]
    );

    const handleClose = useCallback(() => {
        setAnswerMessage(null);
        onBlur(false);
    }, [onBlur]);

    const handleStart = useCallback(() => {
        onAnswer([], exercise.id, false);
        setCurrentAnswerNumber(0);
        setLastAnswerNumber(undefined);
    }, [exercise.id, onAnswer]);

    useEffect(() => {
        if (lastAnswerNumber !== undefined) {
            setAnswerMessage(null);
            onBlur(false);
            setCurrentAnswerNumber((prevState) => prevState + 1);
        }
    }, [lastAnswerNumber]);

    const currentAnswerColumn = useMemo(() => {
        const currentAnswer = exercise.answers?.[currentAnswerNumber];
        return currentAnswer && userAnswers
            ? userAnswers.find((ua) => ua.answerId === currentAnswer.id)?.column
            : undefined;
    }, [currentAnswerNumber, exercise.answers, userAnswers]);

    const total = useMemo(
        () =>
            exercise.answers?.[currentAnswerNumber]
                ? 0
                : Math.ceil(
                      (exercise.answers?.reduce((acc, cur, i) => {
                          const score =
                              userAnswers && cur.column === userAnswers.find((ua) => ua.answerId === cur.id)?.column
                                  ? 1
                                  : 0;
                          return (acc * i + score) / (i + 1);
                      }, 0) || 0) * 100
                  ),
        [currentAnswerNumber, exercise.answers, userAnswers]
    );

    useLayoutEffect(() => onChange?.(), [currentAnswerNumber, exercise.answers, onChange, total]);

    return (
        <div className="exercise-tv">
            <ExerciseBody number={exerciseNumber} text={exercise.text} topic={exercise.topic} />
            {exercise.answers?.[currentAnswerNumber] ? (
                <>
                    <div className="exercise-tv__answers">
                        <div className="exercise-tv__answer">{exercise.answers[currentAnswerNumber].text}</div>
                    </div>
                    <div
                        className={cx("exercise-tv__columns", {
                            "exercise-tv__columns--large": exercise.columns?.every((c) => c.name.length <= 10),
                            "exercise-tv__columns--small-standard": exercise.columns?.some(
                                (c) => (c.name.length >= 15 && c.name.indexOf(" ") === -1) || c.name.length > 30
                            ),
                        })}
                    >
                        {exercise.columns?.map((c) => (
                            <ExerciseTvColumn
                                column={c}
                                fail={
                                    currentAnswerColumn === c.column &&
                                    exercise.answers?.[currentAnswerNumber].column !== c.column
                                }
                                key={`column_${c.column}`}
                                onClick={currentAnswerColumn === undefined ? handleAnswer : undefined}
                                success={
                                    currentAnswerColumn === c.column &&
                                    exercise.answers?.[currentAnswerNumber].column === c.column
                                }
                            />
                        ))}
                    </div>
                    <Modal className="modal-container" open={answerMessage != null} onClose={handleClose}>
                        <div className="modal">{answerMessage}</div>
                    </Modal>
                </>
            ) : (
                <div className="exercise-tv__result">
                    <h3>Ваши ответы:</h3>
                    <div className="exercise-tv__result-columns">
                        {exercise.columns?.map((c) => (
                            <div key={`column_${c.column}`} className="exercise-tv__result-column">
                                <div className="exercise-tv__result-column-name">
                                    {c.nameHtml ? (
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: c.nameHtml,
                                            }}
                                        />
                                    ) : (
                                        c.name
                                    )}
                                </div>
                                {userAnswers
                                    ?.filter((ua) => ua.column === c.column)
                                    .map((ua) => {
                                        const answer = exercise.answers?.find((a) => a.id === ua.answerId);
                                        if (answer) {
                                            return (
                                                <div
                                                    className={cx("exercise-tv__result-column-answer", {
                                                        "exercise-tv__result-column-answer--correct":
                                                            answer.column === c.column,
                                                    })}
                                                    key={`answer_${answer.id}`}
                                                >
                                                    {answer.text}
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                            </div>
                        ))}
                    </div>
                    {!isGrouped && (
                        <>
                            <h3>Результат: {total} %</h3>
                            <h3>{finishMessages[`message${Math.floor(total / 10) * 10}`]}</h3>
                        </>
                    )}
                    <div>
                        <Button color="primary" onClick={handleStart} size="large" variant="contained">
                            Начать сначала
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
