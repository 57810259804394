import type { FC } from "react";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import cx from "classnames";
import * as _ from "lodash";
import { Button, CircularProgress, Modal, TextField } from "@material-ui/core";

import { failMessages, finishMessages, modalMessageTimeout, successMessages } from "../../../constants/custom";
import type { Answer, ExerciseQuestionType } from "../../../types/main";
import { ExerciseBody } from "../../exercise_body";

import "./styles.scss";

const checkAnswer = (correctAnswer: string, userAnswer?: string): boolean => {
    return correctAnswer.toUpperCase() === userAnswer?.trim().toUpperCase();
};

type Props = {
    exercise: ExerciseQuestionType;
    exerciseNumber: number;
    onAnswer: (answer: Record<string, string>, exerciseId: number) => void;
    onBlur: (value: boolean) => void;
    onChange?: () => void;
    userAnswers?: Record<string, string>;
};

export const ExerciseFit: FC<Props> = ({
    exercise,
    exerciseNumber,
    onAnswer,
    onBlur,
    onChange,
    userAnswers,
}: Props) => {
    const column2AnswersByOrder = useMemo(() => {
        const out: Record<string, Answer> = {};
        exercise.answers
            ?.filter((a) => a.column === 2)
            .forEach((a) => {
                if (a.order !== undefined) {
                    out[a.order.toString()] = a;
                }
            });
        return out;
    }, [exercise.answers]);

    const column1Answers = useMemo(
        () =>
            exercise.answers?.filter(
                (a) => a.column === 1 && a.order !== undefined && column2AnswersByOrder[a.order.toString()]
            ) || [],
        [column2AnswersByOrder, exercise.answers]
    );

    const [answerMessage, setAnswerMessage] = useState<string | null>(null);
    const [checkingValue, setCheckingValue] = useState(false);
    const [currentValue, setCurrentValue] = useState<string>("");

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => setCurrentValue(event.target.value),
        []
    );

    const currentAnswer = useMemo(
        () =>
            column1Answers.find((a) => userAnswers?.[column2AnswersByOrder[a.order!.toString()].id.toString()] == null),
        [column1Answers, column2AnswersByOrder, userAnswers]
    );

    const total = useMemo(
        () =>
            Math.ceil(
                (column1Answers?.reduce((acc, cur, i) => {
                    const score =
                        userAnswers &&
                        checkAnswer(
                            column2AnswersByOrder[cur.order!.toString()].text,
                            userAnswers[column2AnswersByOrder[cur.order!.toString()].id.toString()]
                        )
                            ? 1
                            : 0;
                    return (acc * i + score) / (i + 1);
                }, 0) || 0) * 100
            ),
        [exercise, userAnswers]
    );

    useLayoutEffect(() => onChange?.(), [currentAnswer, onChange, total]);

    const handleClose = useCallback(() => {
        setAnswerMessage(null);
        onBlur(false);
    }, [onBlur]);

    const handleAnswer = useCallback(() => {
        if (!currentAnswer) {
            return;
        }
        const id = column2AnswersByOrder[currentAnswer.order!.toString()].id.toString();
        setCheckingValue(true);
        const isCorrect = checkAnswer(column2AnswersByOrder[currentAnswer.order!.toString()].text, currentValue);
        const messages = isCorrect ? successMessages : failMessages;
        setAnswerMessage(_.shuffle(messages)[0]);
        onBlur(true);
        setTimeout(() => {
            onAnswer(
                {
                    ...(userAnswers || {}),
                    [id]: currentValue,
                },
                exercise.id
            );
            setCurrentValue("");
            setCheckingValue(false);
            handleClose();
        }, modalMessageTimeout);
    }, [column2AnswersByOrder, currentAnswer, currentValue, exercise.id, handleClose, onAnswer, onBlur, userAnswers]);

    const handleKeyUp = useCallback(
        (event) => {
            if (event.key === "Enter") {
                handleAnswer();
            }
        },
        [handleAnswer]
    );

    const handleStart = useCallback(() => {
        onAnswer({}, exercise.id);
        setCheckingValue(false);
        setCurrentValue("");
    }, [exercise.id, onAnswer]);

    if (!currentAnswer) {
        return (
            <div className="exercise-fit__result">
                <h3>Ваши ответы:</h3>
                <div className="exercise-fit__result-answers">
                    {column1Answers.map((a1, i) => {
                        const isCorrect =
                            userAnswers &&
                            checkAnswer(
                                column2AnswersByOrder[a1.order!.toString()].text,
                                userAnswers[column2AnswersByOrder[a1.order!.toString()].id.toString()]
                            );
                        return (
                            <div className="exercise-fit__result-answer" key={`answer1_${a1.id}`}>
                                <div className="exercise-fit__result-column-1">
                                    {a1.text.indexOf(`${i + 1}. `) === 0 ? "" : `${i + 1}. `}
                                    {a1.text}
                                </div>
                                <div className="exercise-fit__mdash">&mdash;</div>
                                <div
                                    className={cx("exercise-fit__result-column-2", {
                                        "exercise-fit__result-column-2--correct": isCorrect,
                                    })}
                                >
                                    {userAnswers?.[column2AnswersByOrder[a1.order!.toString()].id.toString()] || (
                                        <>&nbsp;&nbsp;&nbsp;</>
                                    )}
                                </div>
                                {!isCorrect && (
                                    <div className="exercise-fit__result-column-3">
                                        ({column2AnswersByOrder[a1.order!.toString()].text})
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
                <h3>Результат: {total} %</h3>
                <h3>{finishMessages[`message${Math.floor(total / 10) * 10}`]}</h3>
                <div>
                    <Button color="primary" onClick={handleStart} size="large" variant="contained">
                        Начать сначала
                    </Button>
                </div>
            </div>
        );
    }

    const hideMdash =
        currentAnswer.text &&
        (currentAnswer.text.length > 30 || [".", ":", "!", '"'].indexOf(currentAnswer.text.slice(-1)) !== -1);

    return (
        <div className="exercise-fit">
            <ExerciseBody number={exerciseNumber} text={exercise.text} topic={exercise.topic} />
            <div className="exercise-fit__answers">
                <div
                    className={cx("exercise-fit__answer", {
                        "exercise-fit__answer--hide-mdash": hideMdash,
                    })}
                >
                    <div className="exercise-fit__answer-text">
                        {currentAnswer.text} <span className="exercise-fit__mdash">&mdash;</span>
                    </div>
                    <div className="exercise-fit__input-form">
                        <div className="exercise-fit__input-container">
                            <TextField
                                className={cx("exercise-fit__input", {
                                    "exercise-fit__input--success":
                                        checkingValue &&
                                        checkAnswer(
                                            column2AnswersByOrder[currentAnswer.order!.toString()].text,
                                            currentValue
                                        ),
                                    "exercise-fit__input--fail":
                                        checkingValue &&
                                        checkAnswer(
                                            column2AnswersByOrder[currentAnswer.order!.toString()].text,
                                            currentValue
                                        ),
                                })}
                                disabled={checkingValue}
                                onChange={handleChange}
                                onKeyUp={handleKeyUp}
                                value={currentValue}
                            />
                        </div>
                        <div className="exercise-fit__button">
                            <Button
                                color="primary"
                                disabled={checkingValue}
                                fullWidth
                                onClick={handleAnswer}
                                size="large"
                                variant="outlined"
                            >
                                {checkingValue && (
                                    <>
                                        <CircularProgress size={12} />
                                        &nbsp;&nbsp;
                                    </>
                                )}
                                Проверить
                            </Button>
                        </div>
                    </div>
                    <Modal className="modal-container" open={answerMessage != null} onClose={handleClose}>
                        <div className="modal">{answerMessage}</div>
                    </Modal>
                </div>
            </div>
        </div>
    );
};
