import type { FC } from "react";
import React, { useCallback } from "react";
import cx from "classnames";

import "./styles.scss";
import easy from "../../images/easy.png";
import hard from "../../images/hard.png";
import medium from "../../images/medium.png";

type Props = {
  level: "easy" | "medium" | "hard";
  onClick?: () => void;
};

const LevelsIcon: FC<Props> = ({ level, onClick }: Props) => {
  const handleClick = useCallback(() => onClick?.(), [onClick]);

  switch (level) {
    case "easy":
      return (
        <div
          className={cx("levels__icon-container", {
            "levels__icon-container--cursor--pointer": onClick,
          })}
        >
          <img
            alt="Лёгкий"
            className="levels__icon"
            onClick={handleClick}
            src={easy}
          />
        </div>
      );
    case "medium":
      return (
        <div
          className={cx("levels__icon-container", {
            "levels__icon-container--cursor--pointer": onClick,
          })}
        >
          <img
            alt="Средний"
            className="levels__icon"
            onClick={handleClick}
            src={medium}
          />
        </div>
      );
    case "hard":
      return (
        <div
          className={cx("levels__icon-container", {
            "levels__icon-container--cursor--pointer": onClick,
          })}
        >
          <img
            alt="Сложный"
            className="levels__icon"
            onClick={handleClick}
            src={hard}
          />
        </div>
      );
  }
};

export default LevelsIcon;
