import type { Cmi5AUProps, ExerciseQuestionType } from "../../../types/main";

import { EXTENSION_ANSWERS, EXTENSION_QUESTIONS, LAUNCH_MODE_NORMAL } from "../constants";
import { noop } from "./noop";

export const sendTotalScoreStatement = ({
    answers,
    cmi,
    questions,
    score,
}: {
    answers: Record<string, any>;
    cmi: Cmi5AUProps["cmi"];
    questions: ExerciseQuestionType[];
    score: {
        max: number;
        min: number;
        raw: number;
        scaled: number;
    };
}) => {
    if (!cmi || !cmi.isActive() || cmi.getLaunchMode() !== LAUNCH_MODE_NORMAL) {
        return;
    }
    const masteryScore = cmi.getMasteryScore() ?? 0.000001;
    try {
        const stmt = score.scaled >= masteryScore ? cmi.passedStatement(score) : cmi.failedStatement(score);

        // Statement from passedStatement/failedStatement
        // is "cmi5 defined" statement meaning they have the context category value added
        // But this case is "cmi5 allowed" (not "cmi5 defined"), so clear the context category
        stmt.context.contextActivities.category = [];

        stmt.context.extensions = {
            ...(stmt.context.extensions ?? {}),
            [EXTENSION_ANSWERS]: answers,
            [EXTENSION_QUESTIONS]: questions,
        };

        // noop is for async request
        cmi.sendStatement(stmt, noop);
    } catch (ex) {
        throw ex;
    }
};
