import type { FC } from "react";
import React from "react";

import type { ExerciseQuestionType } from "../../types/main";
import { ExerciseBonfire } from "../exercise/bonfire";
import { ExerciseColor } from "../exercise/color";
import { ExerciseConformity } from "../exercise/conformity";
import { ExerciseFit } from "../exercise/fit";
import { ExerciseHouse } from "../exercise/house";
import { ExerciseLetter } from "../exercise/letter";
import { ExerciseOranges } from "../exercise/oranges";
import { ExerciseRubles } from "../exercise/rubles";
import { ExerciseTetris } from "../exercise/tetris";
import { ExerciseTv } from "../exercise/tv";
import { QuestionMultiple } from "../question/multiple";
import { QuestionSingle } from "../question/single";
import { QuestionType3 } from "../question/type_3";
import { QuestionType4 } from "../question/type_4";
import { QuestionType5 } from "../question/type_5";
import { QuestionType6 } from "../question/type_6";
import { Template } from "./constants";

type Props = {
    answers: Record<string, any>;
    exerciseColorMode?: "letters" | "words";
    exerciseProps: {
        exercise: ExerciseQuestionType;
        exerciseNumber: number;
        onAnswer: (answer: any, itemId: number, isCorrect?: boolean) => void;
        userAnswers: any;
    };
    exerciseResult?: JSX.Element;
    isGrouped: boolean;
    itemNumber: number;
    onAnswer: (answer: any, itemId: number, isCorrect?: boolean) => void;
    onBlur: (value: boolean) => void;
    onChange?: (payload?: Record<string, boolean>) => void;
    question: ExerciseQuestionType;
};

export const UnitQuestion: FC<Props> = ({
    answers,
    exerciseColorMode,
    exerciseProps,
    exerciseResult,
    isGrouped,
    itemNumber,
    onAnswer,
    onBlur,
    onChange,
    question,
}: Props) => {
    if (!question.templateId) {
        switch (question.typeId) {
            case 1:
                return (
                    <QuestionSingle
                        onAnswer={onAnswer}
                        question={question}
                        questionNumber={itemNumber}
                        userAnswers={answers[question.id.toString()]}
                    />
                );
            case 2:
                return (
                    <QuestionMultiple
                        onAnswer={onAnswer}
                        question={question}
                        questionNumber={itemNumber}
                        userAnswers={answers[question.id.toString()]}
                    />
                );
            case 3:
                return (
                    <QuestionType3
                        onAnswer={onAnswer}
                        question={question}
                        questionNumber={itemNumber}
                        userAnswer={answers[question.id.toString()]}
                    />
                );
            case 4:
                return (
                    <QuestionType4
                        onAnswer={onAnswer}
                        question={question}
                        questionNumber={itemNumber}
                        userAnswer={answers[question.id.toString()]}
                    />
                );
            case 5:
                return (
                    <QuestionType5
                        onAnswer={onAnswer}
                        question={question}
                        questionNumber={itemNumber}
                        userAnswers={answers[question.id.toString()]}
                    />
                );
            case 6:
                return (
                    <QuestionType6
                        onAnswer={onAnswer}
                        question={question}
                        questionNumber={itemNumber}
                        userAnswer={answers[question.id.toString()]}
                    />
                );
        }
    }
    if (question.templateId === Template.LETTER) {
        return <ExerciseLetter {...exerciseProps} showAnswers={exerciseResult !== undefined} />;
    }
    if (question.templateId === Template.CONFORMITY) {
        return <ExerciseConformity {...exerciseProps} />;
    }
    if (question.templateId === Template.COLOR) {
        return <ExerciseColor {...exerciseProps} mode={exerciseColorMode} showAnswers={exerciseResult !== undefined} />;
    }
    if (question.templateId === Template.TETRIS) {
        return <ExerciseTetris {...exerciseProps} onBlur={onBlur} onChange={onChange} />;
    }
    if (question.templateId === Template.HOUSE) {
        return (
            <ExerciseHouse
                {...exerciseProps}
                isGrouped={isGrouped}
                onChange={onChange}
                showAnswers={exerciseResult !== undefined}
            />
        );
    }
    if (question.templateId === Template.TV) {
        return <ExerciseTv {...exerciseProps} isGrouped={isGrouped} onBlur={onBlur} onChange={onChange} />;
    }
    if (question.templateId === Template.BONFIRE) {
        return <ExerciseBonfire {...exerciseProps} isGrouped={isGrouped} onChange={onChange} />;
    }
    if (question.templateId === Template.RUBLES) {
        return <ExerciseRubles {...exerciseProps} onBlur={onBlur} onChange={onChange} />;
    }
    if (question.templateId === Template.FIT) {
        return <ExerciseFit {...exerciseProps} onBlur={onBlur} onChange={onChange} />;
    }
    if (question.templateId === Template.ORANGES) {
        return <ExerciseOranges {...exerciseProps} isGrouped={isGrouped} onBlur={onBlur} onChange={onChange} />;
    }
    return null;
};
