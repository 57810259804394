import type { FC } from "react";
import React, { useCallback, useMemo } from "react";
import Xarrow from "react-xarrows";
import { shuffle } from "lodash";

import { colors } from "../../../constants/custom";
import { Arrow, ExerciseQuestionType } from "../../../types/main";
import { ExerciseBody } from "../../exercise_body";
import ExerciseConformityItem from "./item";

import "./styles.scss";

type Props = {
    exercise: ExerciseQuestionType;
    exerciseNumber: number;
    onAnswer: (answer: Record<string, Arrow>, exerciseId: number) => void;
    userAnswers?: Record<string, Arrow>;
};

export const ExerciseConformity: FC<Props> = ({ exercise, exerciseNumber, onAnswer, userAnswers }: Props) => {
    const nextColor: string | undefined = useMemo(() => {
        const freeColors = new Set(colors);
        if (userAnswers) {
            Object.keys(userAnswers).forEach((k) => {
                if (userAnswers[k].color && freeColors.has(userAnswers[k].color!)) {
                    freeColors.delete(userAnswers[k].color!);
                }
            });
        }
        return freeColors.size ? freeColors.values().next().value : undefined;
    }, [userAnswers]);
    const selectedIds = useMemo(() => {
        const ids = new Set<number>();
        if (userAnswers) {
            Object.keys(userAnswers).forEach((k) => {
                ids.add(userAnswers[k].startId);
                ids.add(userAnswers[k].endId);
            });
        }
        return ids;
    }, [userAnswers]);
    const handleAnswer = useCallback(
        (arrow: Arrow) => {
            const clearedAnswer: Record<string, Arrow> = {};
            if (userAnswers) {
                Object.keys(userAnswers).forEach((k) => {
                    if (
                        userAnswers[k].startId !== arrow.endId &&
                        userAnswers[k].startId !== arrow.startId &&
                        userAnswers[k].endId !== arrow.endId &&
                        userAnswers[k].endId !== arrow.startId
                    ) {
                        clearedAnswer[k] = userAnswers[k];
                    }
                });
            }
            onAnswer(
                {
                    ...(clearedAnswer || {}),
                    [`arrow_${arrow.start}_${arrow.end}`]: {
                        ...arrow,
                        color: nextColor,
                    },
                },
                exercise.id
            );
        },
        [exercise.id, nextColor, onAnswer, userAnswers]
    );

    const column1 = useMemo(() => shuffle(exercise.answers?.filter((a) => a.column === 1) ?? []), [exercise.answers]);
    const column2 = useMemo(() => shuffle(exercise.answers?.filter((a) => a.column === 2) ?? []), [exercise.answers]);

    return (
        <div className="exercise-conformity">
            <ExerciseBody number={exerciseNumber} text={exercise.text} topic={exercise.topic} />
            <div className="exercise-conformity__columns">
                <div className="exercise-conformity__column-1">
                    {column1.map((a) => (
                        <ExerciseConformityItem
                            addArrow={handleAnswer}
                            color={nextColor}
                            column={1}
                            id={a.id}
                            itemId={`answer_${a.id}`}
                            key={`answer_${a.id}`}
                            selected={selectedIds.has(a.id)}
                            text={a.text}
                        />
                    ))}
                </div>
                <div className="exercise-conformity__column-2">
                    {column2.map((a) => (
                        <ExerciseConformityItem
                            addArrow={handleAnswer}
                            color={nextColor}
                            column={2}
                            id={a.id}
                            itemId={`answer_${a.id}`}
                            key={`answer_${a.id}`}
                            selected={selectedIds.has(a.id)}
                            text={a.text}
                        />
                    ))}
                </div>
                {userAnswers &&
                    Object.keys(userAnswers).map((k) => (
                        <Xarrow
                            animateDrawing={true}
                            color={userAnswers[k].color}
                            end={userAnswers[k].end}
                            key={k}
                            showHead={false}
                            start={userAnswers[k].start}
                            strokeWidth={3}
                        />
                    ))}
            </div>
        </div>
    );
};
