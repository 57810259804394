import type { FC } from "react";
import React from "react";

export const PlayIcon: FC = () => (
  <svg
    aria-hidden="true"
    height={24}
    focusable="false"
    tabIndex={-1}
    viewBox="0 0 24 24"
    width={24}
  >
    <path d="M8 5v14l11-7z"></path>
  </svg>
);
