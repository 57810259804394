import type { Cmi5AUProps } from "../../../types/main";

import { ACTIVITIES_COURSE, LAUNCH_MODE_NORMAL, VERB_ATTEMPTED } from "../constants";
import { noop } from "./noop";

declare let TinCan: any;

export const sendAttemptedStatement = ({ cmi }: { cmi: Cmi5AUProps["cmi"] }) => {
    if (!cmi || !cmi.isActive() || cmi.getLaunchMode() !== LAUNCH_MODE_NORMAL) {
        return;
    }

    const stmt = cmi.prepareStatement(VERB_ATTEMPTED);

    const result: any = {
        duration: TinCan.Utils.convertMillisecondsToISO8601Duration(cmi.getDuration()),
    };
    stmt.result = new TinCan.Result(result);
    stmt.object = {
        objectType: "Activity",
        id: cmi.getActivity().id,
        definition: {
            type: ACTIVITIES_COURSE,
            name: { "ru-RU": "" },
            description: { "ru-RU": "" },
        },
    };

    // noop is for async request
    cmi.sendStatement(stmt, noop);
};
