import type { FC } from "react";
import React, { memo } from "react";
import cx from "classnames";

type Props = {
    length: number;
    step: number;
};

const UnitProgressFC: FC<Props> = ({ length, step }: Props) => {
    const steps = [];
    for (let i = 0; i < length; i++) {
        steps.push(
            <div
                className={cx("unit__question-progress-step", {
                    "unit__question-progress-step--active": i < step,
                })}
                key={i}
            />
        );
    }

    return <div className="unit__question-progress">{steps}</div>;
};

export const UnitProgress = memo(UnitProgressFC);
