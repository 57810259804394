import type { ExerciseQuestionType } from "../../../types/main";
import { getNumbersArray } from "./get_numbers_array";
import { getWordsArray } from "./get_words_array";

type Props = {
    answers: Record<string, any>;
    partiallyCorrect: boolean;
    question: ExerciseQuestionType;
};

export const checkQuestion = ({
    answers,
    partiallyCorrect,
    question,
}: Props): {
    answerPercent: number;
    correctAnswers: string[];
    success: boolean;
    userAnswer?: string;
    userAnswerIds?: string[];
} => {
    switch (question.typeId) {
        case 1:
        case 2: {
            const correctAnswers = question.answers?.filter((a) => a.correct).map((a) => a.id.toString()) ?? [];
            const le = correctAnswers.length; // мощность эталонного множества
            let ka = 0; // количество элементов из ответа тестируемого, входящих в эталон
            let kShtrih = 0; // количество элементов из ответа тестируемого, не входящих в эталон
            const userAnswers = (answers[question.id.toString()] ?? {}) as Record<string, boolean>;
            Object.keys(userAnswers).forEach((v) => {
                if (!userAnswers[v]) {
                    return;
                }
                if (correctAnswers.indexOf(v) !== -1) {
                    ka++;
                } else {
                    kShtrih++;
                }
            });

            let sigma = 1; // степень сходства ответа пользователя и эталонного ответа
            // Ошибка влияет на уменьшение балла больше, чем недостаток правильных ответов
            if (le + kShtrih > 0) {
                // Главное отметить правильные ответы (мягкое наказание за неправильно отмеченные варианты)
                sigma = ka / (le + 1 * kShtrih);
                // Главное не ошибиться — лучше не поставить, чем поставить правильно и не правильно.
                // sigma = ka / (le + 3 * kShtrih);
            }

            const answerPercent = Math.round(100 * sigma * 1000) / 1000;

            return {
                answerPercent,
                correctAnswers,
                success: sigma === 1,
                userAnswerIds: Object.keys(userAnswers).reduce(
                    (acc, v) => (userAnswers[v] ? [...acc, v] : acc),
                    [] as string[]
                ),
            };
        }
        case 3: {
            const userAnswer = (answers[question.id.toString()] ?? "") as string;
            const success =
                question.answers?.find((a) => a.text.toLowerCase() === userAnswer.trim().toLowerCase()) !== undefined;
            const answerPercent = success ? 100 : 0;

            return {
                answerPercent,
                correctAnswers: question.answers?.map((a) => a.id.toString()) ?? [],
                success,
                userAnswer,
            };
        }
        case 4: {
            const userAnswer = (answers[question.id.toString()] ?? "") as string;
            const userAnswerNumbers = getNumbersArray(userAnswer);

            const { answerPercent, sigma } = (question.answers ?? []).reduce(
                (acc, cur) => {
                    const answersCorrect = getNumbersArray(cur.text);

                    const le = answersCorrect.length; // мощность эталонного множества
                    let ka = 0; // количество элементов из ответа тестируемого, входящих в эталон
                    let kShtrih = 0; // количество элементов из ответа тестируемого, не входящих в эталон

                    userAnswerNumbers.forEach((v) => {
                        if (answersCorrect.indexOf(v) !== -1) {
                            ka++;
                        } else {
                            kShtrih++;
                        }
                    });

                    let sigma = 1; // степень сходства ответа пользователя и эталонного ответа
                    // Ошибка влияет на уменьшение балла больше, чем недостаток правильных ответов
                    if (le + kShtrih > 0) {
                        // Главное отметить правильные ответы (мягкое наказание за неправильно отмеченные варианты)
                        sigma = ka / (le + 1 * kShtrih);
                        // Главное не ошибиться — лучше не поставить, чем поставить правильно и не правильно.
                        // sigma = ka / (le + 3 * kShtrih);
                    }

                    if (!partiallyCorrect && sigma !== 1) {
                        sigma = 0;
                    }

                    const answerPercent = Math.round(100 * sigma * 1000) / 1000;

                    if (acc.answerPercent < answerPercent) {
                        return {
                            answerPercent,
                            sigma,
                        };
                    }

                    return acc;
                },
                {
                    answerPercent: 0,
                    sigma: 0,
                }
            );

            return {
                answerPercent,
                correctAnswers: question.answers?.map((a) => a.id.toString()) ?? [],
                success: sigma === 1,
                userAnswer,
            };
        }
        case 5: {
            const userAnswers = (answers[question.id.toString()] ?? {}) as Record<string, string>;

            const result = (question.answers ?? []).reduce(
                (acc, cur, i) => {
                    const userAnswerNumbers = getNumbersArray(userAnswers[cur.id.toString()] ?? "");
                    const answersCorrect = getNumbersArray(cur.text);

                    const le = answersCorrect.length; // мощность эталонного множества
                    let ka = 0; // количество элементов из ответа тестируемого, входящих в эталон
                    let kShtrih = 0; // количество элементов из ответа тестируемого, не входящих в эталон

                    userAnswerNumbers.forEach((v) => {
                        if (answersCorrect.indexOf(v) !== -1) {
                            ka++;
                        } else {
                            kShtrih++;
                        }
                    });

                    let sigma = 1; // степень сходства ответа пользователя и эталонного ответа
                    // Ошибка влияет на уменьшение балла больше, чем недостаток правильных ответов
                    if (le + kShtrih > 0) {
                        // Главное отметить правильные ответы (мягкое наказание за неправильно отмеченные варианты)
                        sigma = ka / (le + 1 * kShtrih);
                        // Главное не ошибиться — лучше не поставить, чем поставить правильно и не правильно.
                        // sigma = ka / (le + 3 * kShtrih);
                    }

                    if (!partiallyCorrect && sigma !== 1) {
                        sigma = 0;
                    }

                    const answerPercent = Math.round(100 * sigma * 1000) / 1000;

                    return {
                        answerPercent: (acc.answerPercent * i + answerPercent) / (i + 1),
                        success: acc.success === null ? sigma === 1 : acc.success && sigma === 1,
                    };
                },
                {
                    answerPercent: 0,
                    success: null,
                } as {
                    answerPercent: number;
                    success: boolean | null;
                }
            );

            const answerPercent = Math.ceil(result.answerPercent);
            const questionAnswers = question.answers?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)) ?? [];

            return {
                answerPercent,
                correctAnswers: questionAnswers.map((a) => a.id.toString()),
                success: Boolean(result.success),
                userAnswer: JSON.stringify(userAnswers),
            };
        }
        case 6: {
            const userAnswer = (answers[question.id.toString()] ?? "") as string;
            const userAnswerWords = getWordsArray(userAnswer);

            const { answerPercent, sigma } = (question.answers ?? []).reduce(
                (acc, cur) => {
                    const answersCorrect = getWordsArray(cur.text);

                    const le = answersCorrect.length; // мощность эталонного множества
                    let ka = 0; // количество элементов из ответа тестируемого, входящих в эталон
                    let kShtrih = 0; // количество элементов из ответа тестируемого, не входящих в эталон

                    userAnswerWords.forEach((v) => {
                        if (answersCorrect.indexOf(v) !== -1) {
                            ka++;
                        } else {
                            kShtrih++;
                        }
                    });

                    let sigma = 1; // степень сходства ответа пользователя и эталонного ответа
                    // Ошибка влияет на уменьшение балла больше, чем недостаток правильных ответов
                    if (le + kShtrih > 0) {
                        // Главное отметить правильные ответы (мягкое наказание за неправильно отмеченные варианты)
                        sigma = ka / (le + 1 * kShtrih);
                        // Главное не ошибиться — лучше не поставить, чем поставить правильно и не правильно.
                        // sigma = ka / (le + 3 * kShtrih);
                    }

                    if (!partiallyCorrect && sigma !== 1) {
                        sigma = 0;
                    }

                    const answerPercent = Math.round(100 * sigma * 1000) / 1000;

                    if (acc.answerPercent < answerPercent) {
                        return {
                            answerPercent,
                            sigma,
                        };
                    }

                    return acc;
                },
                {
                    answerPercent: 0,
                    sigma: 0,
                }
            );

            return {
                answerPercent,
                correctAnswers: question.answers?.map((a) => a.id.toString()) ?? [],
                success: sigma === 1,
                userAnswer,
            };
        }
        default:
            return {
                answerPercent: 0,
                correctAnswers: [],
                success: false,
            };
    }
};
