import { withStyles } from "@material-ui/core/styles";
import { Slider } from "../slider/slider";

export const SliderSound = withStyles({
  root: {
    color: "#ffffff",
    height: 4,
    paddingBottom: 4,
    paddingTop: 4,
  },
  thumb: {
    backgroundColor: "#ffffff",
    height: 12,
    marginLeft: -6,
    marginTop: -4,
    width: 12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    color: "#ffffff",
    height: 4,
  },
  rail: {
    color: "rgba(255, 255, 255, .5)",
    height: 4,
  },
})(Slider);
