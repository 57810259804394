import type { ChangeEvent, RefObject } from "react";
import { useEffect, useCallback, useState } from "react";

export const useVideoSound = (videoElement: RefObject<HTMLVideoElement>) => {
  const [muted, setMuted] = useState(false);
  const [
    lastPositiveCommittedVolumeLevel,
    setLastPositiveCommittedVolumeLevel,
  ] = useState(0.5);
  const [volumeLevel, setVolumeLevel] = useState(0.5);

  const toggleMute = useCallback(() => {
    setMuted(!muted);
    if (muted && volumeLevel === 0) {
      setVolumeLevel(lastPositiveCommittedVolumeLevel);
    }
  }, [lastPositiveCommittedVolumeLevel, muted, volumeLevel]);

  useEffect(() => {
    if (videoElement.current) {
      muted
        ? (videoElement.current.muted = true)
        : (videoElement.current.muted = false);
    }
  }, [muted, videoElement]);

  const handleVolumeLevel = useCallback(
    (
      event: ChangeEvent<Record<string, unknown>>,
      newValue: number | number[]
    ) => {
      const manualChange = Number(newValue);
      setMuted(manualChange === 0);
      setVolumeLevel(manualChange);
    },
    []
  );

  const handleCommittedVolumeLevel = useCallback(
    (
      event: ChangeEvent<Record<string, unknown>>,
      newValue: number | number[]
    ) => {
      const manualChange = Number(newValue);
      if (manualChange > 0) {
        setLastPositiveCommittedVolumeLevel(manualChange);
      }
    },
    []
  );

  useEffect(() => {
    if (videoElement.current) {
      videoElement.current.volume = volumeLevel;
    }
  }, [volumeLevel, videoElement]);

  return {
    handleCommittedVolumeLevel,
    handleVolumeLevel,
    muted,
    toggleMute,
    volumeLevel,
  };
};
