import type { FC } from "react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import Xarrow from "react-xarrows";

type Props = {
  color?: string;
  column: number;
  id: number;
  itemId: string;
  startRef: React.MutableRefObject<null>;
};

const ExerciseConformityPoint: FC<Props> = ({
  color,
  column,
  id,
  itemId,
  startRef,
}: Props) => {
  const endRef = useRef(null);

  const [position, setPosition] = useState({});
  const [beingDragged, setBeingDragged] = useState(false);
  const style = useMemo(
    () => ({
      ...{
        left: 0,
        top: 0,
      },
      ...position,
    }),
    [position]
  );
  const onDrag = useCallback(
    (e) =>
      setPosition({
        height: 0,
        left: e.clientX,
        opacity: 0,
        position: "fixed",
        top: e.clientY,
        transform: "none",
        width: 0,
      }),
    []
  );
  const onDragEnd = useCallback((e) => {
    setPosition({});
    setBeingDragged(false);
  }, []);
  const onDragStart = useCallback(
    (e) => {
      setBeingDragged(true);
      e.dataTransfer.setData("column", column);
      e.dataTransfer.setData("id", id);
      e.dataTransfer.setData("itemId", itemId);
    },
    [column, id, itemId]
  );

  return (
    <>
      <div
        className="exercise-conformity__connect-point"
        draggable
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        ref={endRef}
        style={style}
      />
      {beingDragged ? (
        <Xarrow
          color={color}
          dashness
          end={endRef}
          headSize={4}
          showHead
          start={startRef}
          strokeWidth={3}
        />
      ) : null}
    </>
  );
};

export default React.memo(ExerciseConformityPoint);
