import exercises1 from "../../data/exercises1_letter.json";
import exercises2 from "../../data/exercises2_conformity.json";
import exercises3 from "../../data/exercises3_color.json";
import exercises4 from "../../data/exercises4_tetris.json";
import exercises5 from "../../data/exercises5_house.json";
import exercises6 from "../../data/exercises6_tv.json";
import exercises7 from "../../data/exercises7_bonfire.json";
import exercises9 from "../../data/exercises9_rubles.json";
import exercises12 from "../../data/exercises12_fit.json";
import exercises13 from "../../data/exercises13_oranges.json";

export const ACTIVITIES_CMI_INTERACTION = "http://adlnet.gov/expapi/activities/cmi.interaction";
export const ACTIVITIES_COURSE = "http://adlnet.gov/expapi/activities/course";
export const EXTENSION_ANSWERS = "https://rus-na5.ru/xapi/cmi5/extension/answers";
export const EXTENSION_QUESTIONS = "https://rus-na5.ru/xapi/cmi5/extension/questions";
export const LAUNCH_MODE_NORMAL = "Normal";
export const VERB_ANSWERED = "http://adlnet.gov/expapi/verbs/answered";
export const VERB_ATTEMPTED = "http://adlnet.gov/expapi/verbs/attempted";

export enum Template {
    LETTER = 1,
    CONFORMITY = 2,
    COLOR = 3,
    TETRIS = 4,
    HOUSE = 5,
    TV = 6,
    BONFIRE = 7,
    RUBLES = 9,
    FIT = 12,
    ORANGES = 13,
}

export const checkExerciseAccess = new Set([
    undefined,
    Template.LETTER,
    Template.CONFORMITY,
    Template.COLOR,
    Template.HOUSE,
]);

export const checkQuestionAccess = new Set([3, 4, 5, 6]);

export const allExercisesData = [
    ...exercises1,
    ...exercises2,
    ...exercises3,
    ...exercises4,
    ...exercises5,
    ...exercises6,
    ...exercises7,
    ...exercises9,
    ...exercises12,
    ...exercises13,
];

export const groupModeExercisesTemplateIds = [Template.HOUSE, Template.TV, Template.BONFIRE, Template.ORANGES];
