import React, { FC, useCallback } from "react";
import cx from "classnames";

import { QuestionChildren } from "../../../types/main";

type Props = {
  child: QuestionChildren;
  enableClick?: boolean;
  onDrop: (data: { answer: string; child: QuestionChildren }) => void;
  userAnswer?: string;
};

const ExerciseOrangesWord: FC<Props> = ({
  child,
  enableClick,
  onDrop,
  userAnswer,
}: Props) => {
  const handleClick = useCallback(
    () =>
      enableClick &&
      userAnswer &&
      onDrop({
        answer: "",
        child,
      }),
    [child, enableClick, onDrop, userAnswer]
  );
  const handleDragOver = useCallback((e) => e.preventDefault(), []);
  const handleDrop: React.DragEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      onDrop({
        answer: e.dataTransfer.getData("text"),
        child,
      });
    },
    [child, onDrop]
  );
  return (
    <div className="exercise-oranges__word">
      <div className="exercise-oranges__word-container">
        <div className="exercise-oranges__word-part">
          {child.textArray?.[0]}
        </div>
        <div
          className={cx("exercise-oranges__word-space", {
            "exercise-oranges__word-space--active": userAnswer,
            "exercise-oranges__word-space--clickable":
              enableClick && userAnswer,
          })}
          onClick={handleClick}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {userAnswer}
        </div>
        <div className="exercise-oranges__word-part">
          {child.textArray?.[1]}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ExerciseOrangesWord);
