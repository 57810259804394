import type { FC } from "react";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import cx from "classnames";
import { shuffle } from "lodash";
import { Button, CircularProgress } from "@material-ui/core";

import { finishMessages } from "../../constants/custom";
import type { Cmi5AUProps, Content, ExerciseQuestionType, Level, VideoDataType } from "../../types/main";
import LevelsSelector from "../levels";
import { UnitContent } from "./content";
import { UnitResult } from "./result";
import { UnitUnit } from "./unit";
import { Video } from "./video/video";

import { contentData } from "../../data/content";
import type1Questions from "../../data/questions/type1.json";
import type2Questions from "../../data/questions/type2.json";
import type3Questions from "../../data/questions/type3.json";
import type4Questions from "../../data/questions/type4.json";
import type5Questions from "../../data/questions/type5.json";
import type6Questions from "../../data/questions/type6.json";
import { videoData } from "../../data/video";
import {
    allExercisesData,
    checkExerciseAccess,
    checkQuestionAccess,
    groupModeExercisesTemplateIds,
    Template,
} from "./constants";
import { useTerminate } from "./hooks/use_terminate";
import { captureInteractions } from "./utils/capture_interactions";
import { checkQuestion } from "./utils/check_question";
import { combineIntersected } from "./utils/combine_intersected";
import { sendAttemptedStatement } from "./utils/send_attempted_statement";
import { sendTotalScoreStatement } from "./utils/send_total_score_statement";

import "./styles.scss";

const enableLevelSelect = false;

// https://github.com/AICC/CMI-5_Spec_Current/blob/quartz/cmi5_spec.md#au_requirements
// An AU MUST:

// Implement the runtime launch interface as defined in Section 8.
// Implement runtime communication as defined in the xAPI Specification.
// Implement State API requirements in this specification as defined in Section 10.
// Implement Profile API requirements in this specification as defined in Section 11.
// Implement Statement API requirements as defined in Section 7.1.

// 7.1 AU Statement API Requirements

// 7.1.1 First Statement API Call
// The AU MUST issue a statement to the LRS after being launched, initialized, and ready for learner interaction using the Initialized verb as described in Section 9.3.2.

// 7.1.2 Last Statement Call
// The AU MUST issue a Terminated statement to the LRS as described in Section 9.3.8 as the last statement in a session.

// Once the AU has determined that the session will end (e.g. by user action or some other means) the AU SHOULD issue a Terminated statement.

// Circumstances under which the AU may determine that the session is ending could include:

// Monitoring for browser window or application close events
// An AU defined user action for closing the AU session
// Other AU defined events such as a timeout from user inactivity

// 7.1.3 Types of Statements
// The statements issued within an AU session could fall within the following categories:

// "cmi5 defined" - Statements using cmi5 defined verbs, category id as defined in section 9.6.2.1, and context template.
// "cmi5 allowed" - Statements using any verb and cmi5 context template (but NOT including cmi5 category id as defined in section 9.6.2.1).
// "cmi5 not-allowed" - Any statements not conforming with the cmi5 specification.
// "cmi5 allowed" statements posted by the AU MUST occur between cmi5 statements using the "Initialized" verb and the "Terminated" verb. "cmi5 allowed" statements are not considered in cmi5 defined session management and completion rules.

// To satisfy the cmi5 protocol, you will need the following params
// fetch: a url to retrieve an access token for your XAPI server
// endpoint: the root endpoint for your XAPI server
// activityId: IRI/id for the XAPI object this assignable unit represents (callbacks to 'passed', 'failed' etc. will use this activity id)
// registration: basically an XAPI session id
// actor: account for which results will be applied (passed as a json XAPI actor object)

// http://localhost:3000/?fetch=http://qa-pal.ict.usc.edu/api/1.0/cmi5/accesstoken2basictoken?access_token=41c847e0-fccd-11e8-8b7f-cf001aed3365&endpoint=http://qa-pal.ict.usc.edu/api/1.0/cmi5/&activityId=http://pal.ict.usc.edu/lessons/cmi5-ex1&registration=957f56b7-1d34-4b01-9408-3ffeb2053b28&actor=%7B%22objectType%22:%20%22Agent%22,%22name%22:%20%22taflearner1%22,%22account%22:%20%7B%22homePage%22:%20%22http://pal.ict.usc.edu/xapi/users%22,%22name%22:%20%225c0eec7993c7cf001aed3365%22%7D%7D
// fetch=http://qa-pal.ict.usc.edu/api/1.0/cmi5/accesstoken2basictoken?access_token=41c847e0-fccd-11e8-8b7f-cf001aed3365
// endpoint=http://qa-pal.ict.usc.edu/api/1.0/cmi5/
// activityId=http://pal.ict.usc.edu/lessons/cmi5-ex1
// registration=957f56b7-1d34-4b01-9408-3ffeb2053b28
// actor=%7B%22objectType%22:%20%22Agent%22,%22name%22:%20%22taflearner1%22,%22account%22:%20%7B%22homePage%22:%20%22http://pal.ict.usc.edu/xapi/users%22,%22name%22:%20%225c0eec7993c7cf001aed3365%22%7D%7D

// http://localhost:3000/?fetch=http://self-test/accesstoken/?api=1&subdomain=lrs&domain=21
// endpoint=http://self-test/?api=1&subdomain=lrs&domain=21
// activityId=
// registration=
// actor=
/**
 * An example question that can be wrapped as a child of Cmi5AssignableUnit.
 *
 * The import piece to note is the use of the injected action properties
 * 'passed' and 'failed', which the question can use to submit results.
 */

type Props = {
    demoDataType: "content" | "exercises" | "test" | "video";
    exerciseColorMode?: "letters" | "words";
    localFile?: string;
    onChange?: (payload?: Record<string, boolean>) => void;
} & Cmi5AUProps;

// props includes special actions for passed({score:1.0}) and failed({score: 0.0 })
// These are wrappers for cmi.passed and cmi.failed
// that make sure cmi has initialized before score is actually sent
export const Unit: FC<Props> = ({ cmi, demoDataType, exerciseColorMode, localFile, onChange }: Props) => {
    const [itemNumber, setItemNumber] = useState<number>(1);
    const [level, setLevel] = useState<Level>();
    const [isGrouped, setIsGrouped] = useState(false);
    const [localData, setLocalData] = useState<ExerciseQuestionType[] | Content | VideoDataType>();

    const data = useMemo(() => {
        if (localFile && /^[a-zA-Z0-9_-]+$/.test(localFile)) {
            const path = process.env.PUBLIC_URL ?? "";
            if (localData === undefined) {
                const time = new Date().getTime();
                fetch(`${path}/data/${localFile}.json?time=${time}`)
                    .then((response) => response.json())
                    .then(setLocalData);
            }
            if (localData !== undefined && !Array.isArray(localData)) {
                if ("additionalFile" in localData) {
                    localData.additionalFile = `${path}/data/${localFile}/${localData.additionalFile}`;
                }
                if ("video" in localData) {
                    if (localData.poster) {
                        localData.poster = `${path}/data/${localFile}/${localData.poster}`;
                    }
                    localData.video.forEach((v) => {
                        v.src = `${path}/data/${localFile}/${v.src}`;
                    });
                }
                if ("images" in localData) {
                    localData.file = `${path}/data/${localFile}/${localData.file}`;
                    localData.images.forEach((v) => {
                        v.original = `${path}/data/${localFile}/${v.original}`;
                        v.thumbnail = `${path}/data/${localFile}/${v.thumbnail}`;
                    });
                }
            }
            return localData;
        }
        switch (demoDataType) {
            case "content":
                return contentData;
            case "exercises":
                return allExercisesData as ExerciseQuestionType[];
            case "video":
                return videoData;
            default:
                return [
                    ...type1Questions,
                    ...type2Questions,
                    ...type3Questions,
                    ...type4Questions,
                    ...type5Questions,
                    ...type6Questions,
                ] as ExerciseQuestionType[];
        }
    }, [demoDataType, localData, localFile]) as Content | ExerciseQuestionType[] | VideoDataType | undefined;

    const [triggerOfShuffle, setTriggerOfShuffle] = useState<boolean>(false);
    const [hideGroupMode, exercisesQuestionsData] = useMemo(() => {
        if (!Array.isArray(data)) {
            return [false, []];
        }
        let isTest = false;
        const out = data
            .filter((v) => !isGrouped || groupModeExercisesTemplateIds.indexOf(v.templateId ?? 0) !== -1)
            .map((v) => {
                if (!v.templateId) {
                    isTest = true;
                }
                if (v.templateId === Template.TV) {
                    return {
                        ...v,
                        answers: shuffle(v.answers ?? []),
                    };
                }
                if (!v.templateId && v.shuffleAnswers) {
                    return {
                        ...v,
                        answers: shuffle(v.answers ?? []),
                    };
                }
                return v;
            });
        return [isTest, out];
    }, [data, isGrouped, triggerOfShuffle]);

    const levels = useMemo(
        () =>
            exercisesQuestionsData.find((v) => v.level === null)
                ? ["easy", "medium", "hard"]
                : exercisesQuestionsData
                      .map((v) => v.level)
                      .filter((value, index, self) => self.indexOf(value) === index),
        [exercisesQuestionsData]
    );

    const dataByLevel = useMemo(() => {
        if (enableLevelSelect) {
            return exercisesQuestionsData
                .filter((q) => q.level === null || q.level === level)
                .sort((a, b) => a.order - b.order);
        }
        return exercisesQuestionsData.sort((a, b) => a.order - b.order);
    }, [exercisesQuestionsData, level]);
    const item = dataByLevel[itemNumber - 1] as ExerciseQuestionType | undefined;

    const [answers, setAnswers] = useState<Record<string, any>>({});
    const [team, setTeam] = useState<Record<string, 1 | 2>>({});
    const currentTeam = useMemo(() => team[item?.id.toString() || ""] || 1, [item, team]);
    const [teamScore, setTeamScore] = useState<Record<string, Record<1 | 2, number>>>({});
    const handleAnswer = useCallback(
        (answer: any, itemId: number, isCorrect?: boolean) => {
            const id = itemId.toString();
            setAnswers((prevState) => ({
                ...(prevState || {}),
                [id]: answer,
            }));
            if (isGrouped) {
                if (
                    typeof answer === "object" &&
                    (answer == null ||
                        (Array.isArray(answer) && answer.length === 0) ||
                        (!Array.isArray(answer) && Object.keys(answer).length === 0))
                ) {
                    setTeam((prevState) => ({
                        ...(prevState || {}),
                        [id]: 1,
                    }));
                    setTeamScore((prevState) => ({
                        ...(prevState || {}),
                        [id]: {
                            [1]: 0,
                            [2]: 0,
                        },
                    }));
                } else {
                    const scoreAdditional = isCorrect ? 1 : 0;
                    setTeamScore((prevState) => ({
                        ...(prevState || {}),
                        [id]: {
                            ...(prevState[id] || {}),
                            [currentTeam]: prevState[id]?.[currentTeam]
                                ? prevState[id][currentTeam] + scoreAdditional
                                : scoreAdditional,
                        },
                    }));
                    setTeam((prevState) => ({
                        ...(prevState || {}),
                        [id]: prevState[id] !== 2 ? 2 : 1,
                    }));
                }
            }
        },
        [currentTeam, isGrouped]
    );

    useTerminate(cmi);

    useEffect(() => {
        setAnswers({});
        setResults({});
        setTeam({});
        setTeamScore({});
    }, [isGrouped]);

    const resetLevel = useCallback(() => {
        if (isGrouped) {
            setAnswers({});
            setResults({});
            setTeam({});
            setTeamScore({});
        }
        setItemNumber(1);
        setLevel(undefined);
        setTestTotal(undefined);
        setExerciseResult(undefined);
        setTriggerOfShuffle((prevState) => !prevState);
    }, [isGrouped]);

    const handleChangeGroupMode = useCallback(
        (value: boolean) => {
            setIsGrouped(value);
            if (!enableLevelSelect) {
                resetLevel();
            }
        },
        [resetLevel]
    );

    const previousItem = useCallback(() => {
        setItemNumber((prevState) => (prevState > 1 ? prevState - 1 : prevState));
        setExerciseResult(undefined);
    }, []);

    const nextItem = useCallback(() => {
        setItemNumber((prevState) => (prevState < dataByLevel.length ? prevState + 1 : prevState));
        setExerciseResult(undefined);
    }, [dataByLevel.length]);

    const [testTotal, setTestTotal] = useState<number>();

    const handleSubmit = useCallback(() => {
        resetLevel();
    }, [resetLevel]);

    const handleSubmitTest = useCallback(() => {
        setTimeout(
            () =>
                sendAttemptedStatement({
                    cmi,
                }),
            0
        );
        setAnswers({});
        setResults({});
        resetLevel();
    }, [cmi, resetLevel]);

    const handleCheckTest = useCallback(() => {
        const interactionTrackingData: any[] = [];

        let sumPercent = 0;
        dataByLevel.forEach((v) => {
            const { answerPercent, correctAnswers, success, userAnswer, userAnswerIds } = checkQuestion({
                answers,
                partiallyCorrect: false,
                question: v,
            });
            sumPercent += answerPercent;

            const scoreObj = {
                max: 100,
                min: 0,
                raw: answerPercent,
                scaled: answerPercent / 100,
            };

            switch (v.typeId) {
                case 1:
                case 2: {
                    interactionTrackingData.push({
                        ...v,
                        userAnswerIds,
                        scoreObj,
                        success,
                        interactionType: "choice",
                        description: "",
                        correctAnswers,
                    });

                    break;
                }
                case 3: {
                    interactionTrackingData.push({
                        ...v,
                        userAnswer,
                        scoreObj,
                        success,
                        interactionType: "fill-in",
                        description: "",
                        correctAnswers,
                    });
                    break;
                }
                case 4: {
                    interactionTrackingData.push({
                        ...v,
                        userAnswer,
                        scoreObj,
                        success,
                        interactionType: "fill-in",
                        description: "",
                        correctAnswers,
                    });
                    break;
                }
                case 5: {
                    interactionTrackingData.push({
                        ...v,
                        userAnswer,
                        scoreObj,
                        success,
                        interactionType: "other",
                        description: "",
                        correctAnswers,
                    });
                    break;
                }
                case 6: {
                    interactionTrackingData.push({
                        ...v,
                        userAnswer,
                        scoreObj,
                        success,
                        interactionType: "fill-in",
                        description: "",
                        correctAnswers,
                    });
                    break;
                }
            }
        });
        const total = Math.ceil(sumPercent / dataByLevel.length);
        setTestTotal(total);

        // interactionType: "long-fill-in",
        // interactionType: "numeric",
        setTimeout(
            () =>
                captureInteractions({
                    cmi,
                    interactionList: interactionTrackingData,
                }),
            0
        );

        setTimeout(
            () =>
                sendTotalScoreStatement({
                    answers,
                    cmi,
                    questions: dataByLevel,
                    score: {
                        max: 100,
                        min: 0,
                        raw: total,
                        scaled: total / 100,
                    },
                }),
            0
        );
    }, [answers, cmi, dataByLevel]);

    const [results, setResults] = useState<
        Record<
            string,
            {
                answerPercent: number;
                success: boolean;
            }
        >
    >({});
    const handleCheckAnswerClick = useCallback(() => {
        if (!item) {
            return;
        }
        const { answerPercent, success } = checkQuestion({
            answers,
            partiallyCorrect: false,
            question: item,
        });
        setResults({
            ...results,
            [item.id.toString()]: {
                answerPercent,
                success,
            },
        });
    }, [answers, item, results]);

    const [exerciseResult, setExerciseResult] = useState<JSX.Element>();
    const handleCheckExercise = useCallback(() => {
        const userAnswers = answers[item?.id.toString() || ""] ?? {};
        const userAnswersArray = Object.keys(userAnswers).map((k) => userAnswers[k]);
        switch (item?.templateId) {
            case 1:
                {
                    const le = item.children?.length ?? 0;
                    const ka =
                        item.children?.filter((c) =>
                            c.answers.find(
                                (a) => a.text.toLowerCase() === (userAnswers?.[c.id.toString()]?.toLowerCase() ?? "")
                            )
                        ).length ?? 0;
                    const answerPercent = le === 0 ? 100 : Math.round(100 * (ka / le) * 1000) / 1000;
                    const total = Math.ceil(answerPercent);
                    setExerciseResult(
                        <>
                            <h3>Результат: {total} %</h3>
                            <h3>{finishMessages[`message${Math.floor(total / 10) * 10}`]}</h3>
                        </>
                    );
                }
                break;
            case 2:
                {
                    const red = "#ff0039";
                    const green = "#00d146";
                    const le = item.answers?.filter((a) => a.column === 1).length ?? 0;
                    let ka = 0;
                    let kShtrih = 0;
                    const answer = Object.keys(userAnswers).map((k) => {
                        const start = item.answers?.find((a) => a.id === userAnswers[k].startId);
                        const end = item.answers?.find((a) => a.id === userAnswers[k].endId);
                        const correct = start && end && start.column !== end.column && start.order === end.order;
                        if (correct) {
                            ka++;
                        } else {
                            kShtrih++;
                        }
                        return {
                            ...userAnswers[k],
                            color: correct ? green : red,
                        };
                    });

                    const id = item.id.toString();
                    setAnswers((prevState) => ({
                        ...(prevState || {}),
                        [id]: answer,
                    }));

                    let sigma = 1;
                    if (le + kShtrih > 0) {
                        sigma = ka / (le + 1 * kShtrih);
                    }
                    const answerPercent = Math.round(100 * sigma * 1000) / 1000;
                    const total = Math.ceil(answerPercent);
                    setExerciseResult(
                        <>
                            <h3>Результат: {total} %</h3>
                            <h3>{finishMessages[`message${Math.floor(total / 10) * 10}`]}</h3>
                        </>
                    );
                }
                break;
            case 3:
                {
                    let le = 0;
                    let ka = 0;
                    let kShtrih = 0;
                    item.answers?.forEach((a) => {
                        let userIntervals = userAnswersArray
                            .filter((v: any) => v && v.color === a.color)
                            .map((v: any) => [v.positionFrom, v.positionTo]);
                        let ints = combineIntersected(userIntervals);
                        while (ints) {
                            userIntervals = ints;
                            ints = combineIntersected(userIntervals);
                        }
                        userIntervals = userIntervals.sort((a, b) => a[0] - b[0]);
                        if (exerciseColorMode === "words") {
                            le += a.words?.length ?? 0;
                            userIntervals.forEach((ui) => {
                                if (a.words?.find((w) => w[1] >= ui[0] && ui[1] >= w[0])) {
                                    ka++;
                                } else {
                                    kShtrih++;
                                }
                            });
                        }
                        if (exerciseColorMode === "letters") {
                            le += a.words?.reduce((prev, curr) => prev + curr[1] - curr[0] + 1, 0) ?? 0;

                            const userIntervalsLength =
                                userIntervals.reduce((prev, curr) => prev + curr[1] - curr[0] + 1, 0) ?? 0;

                            let intervals = [...userIntervals, ...(a.words || [])];
                            let combinedIntervals = combineIntersected(intervals);
                            while (combinedIntervals) {
                                intervals = combinedIntervals;
                                combinedIntervals = combineIntersected(intervals);
                            }

                            const intervalsLength =
                                intervals.reduce((prev, curr) => prev + curr[1] - curr[0] + 1, 0) ?? 0;

                            kShtrih += intervalsLength - le;
                            ka += userIntervalsLength - (intervalsLength - le);
                        }
                        return 0;
                    });

                    let sigma = 1;
                    if (le + kShtrih > 0) {
                        sigma = ka / (le + 1 * kShtrih);
                    }
                    const total = Math.ceil(Math.round(100 * sigma * 1000) / 1000);
                    setExerciseResult(
                        <>
                            <h3>Результат: {total >= 0 ? <>{total} %</> : <>&mdash;</>}</h3>
                            {total >= 0 && <h3>{finishMessages[`message${Math.floor(total / 10) * 10}`]}</h3>}
                        </>
                    );
                }
                break;
            case 5:
                {
                    const total = Math.ceil(
                        (item.answers?.reduce((acc, cur, i) => {
                            const score =
                                userAnswersArray &&
                                cur.column === userAnswersArray.find((ua) => ua.answerId === cur.id)?.column
                                    ? 1
                                    : 0;
                            return (acc * i + score) / (i + 1);
                        }, 0) || 0) * 100
                    );
                    setExerciseResult(
                        <>
                            <h3>Результат: {total} %</h3>
                            <h3>{finishMessages[`message${Math.floor(total / 10) * 10}`]}</h3>
                        </>
                    );
                }
                break;
        }
    }, [answers, item]);

    const repeatExercise = useCallback(() => {
        item && handleAnswer(undefined, item.id);
        setExerciseResult(undefined);
    }, [handleAnswer, item]);

    const questionCount = useMemo(() => (isGrouped ? item?.answers?.length || item?.children?.length || 0 : 0), [
        isGrouped,
        item,
    ]);
    const teamQuestionCount = Math.ceil(questionCount / 2);
    const team1Score = useMemo(() => (isGrouped ? teamScore[item?.id.toString() || ""]?.[1] || 0 : 0), [
        isGrouped,
        item,
        teamScore,
    ]);
    const team2Score = useMemo(() => (isGrouped ? teamScore[item?.id.toString() || ""]?.[2] || 0 : 0), [
        isGrouped,
        item,
        teamScore,
    ]);

    const exerciseProps = useMemo(
        () => ({
            exercise: item!,
            exerciseNumber: itemNumber,
            onAnswer: handleAnswer,
            userAnswers: answers[item?.id.toString() || ""],
        }),
        [answers, handleAnswer, item, itemNumber]
    );

    const handleChange = useCallback(
        () =>
            onChange?.({
                fillSpace: data !== undefined && !Array.isArray(data),
            }),
        [data, onChange]
    );
    useLayoutEffect(() => handleChange, [exerciseResult, handleChange, item?.id, level, levels, testTotal]);

    const [blur, setBlur] = useState(false);

    if (data === undefined) {
        return <CircularProgress />;
    }

    if (!Array.isArray(data) && "images" in data) {
        return <UnitContent blur={blur} data={data} onChange={handleChange} />;
    }

    if (!Array.isArray(data) && "video" in data) {
        return (
            data.video[0] && (
                <Video
                    aspectRatio={data.video[0].aspectRatio}
                    blur={blur}
                    poster={data.poster}
                    quality={data.video[0].quality}
                    src={data.video[0].src}
                    type={data.video[0].type}
                />
            )
        );
    }

    if (enableLevelSelect && !level) {
        return (
            <div className={cx("unit", { "unit--blur": blur })}>
                <LevelsSelector
                    isGrouped={isGrouped}
                    levels={levels}
                    onChangeMode={!hideGroupMode ? handleChangeGroupMode : undefined}
                    onClick={setLevel}
                />
            </div>
        );
    }

    if (!item) {
        return (
            <div className={cx("unit", { "unit--blur": blur })}>
                <div className="unit__message">Вопросов нет</div>
                {enableLevelSelect && (
                    <div className="unit__controls">
                        <Button color="primary" onClick={resetLevel} size="large" variant="contained">
                            Выбрать другой уровень
                        </Button>
                    </div>
                )}
            </div>
        );
    }

    if (testTotal !== undefined) {
        return (
            <UnitResult
                answers={answers}
                dataByLevel={dataByLevel}
                enableLevelSelect={enableLevelSelect}
                item={item}
                itemNumber={itemNumber}
                level={level}
                onSubmitTest={handleSubmitTest}
                testTotal={testTotal}
            />
        );
    }

    return (
        <UnitUnit
            answers={answers}
            blur={blur}
            сheckButtonTitle={item.templateId ? "Проверить" : "Проверить весь тест"}
            currentTeam={currentTeam}
            enableLevelSelect={enableLevelSelect}
            exerciseColorMode={exerciseColorMode}
            exerciseProps={exerciseProps}
            exerciseResult={exerciseResult}
            finish={
                isGrouped &&
                exerciseProps.userAnswers &&
                Object.keys(exerciseProps.userAnswers).length === questionCount
            }
            isGrouped={isGrouped}
            item={item}
            itemNumber={itemNumber}
            itemResult={results[item.id.toString()]}
            itemsLength={dataByLevel.length}
            levelIcon={level ?? (item.level as Level) ?? "easy"}
            onAnswer={handleAnswer}
            onBlur={setBlur}
            onChange={onChange}
            onChangeMode={!hideGroupMode ? handleChangeGroupMode : undefined}
            onCheckAnswerClick={
                !exerciseResult &&
                !isGrouped &&
                !item.templateId &&
                checkQuestionAccess.has(item.typeId ?? 0) &&
                !results[item.id.toString()]
                    ? handleCheckAnswerClick
                    : undefined
            }
            onCheckButtonClick={
                !exerciseResult && !isGrouped && checkExerciseAccess.has(item.templateId)
                    ? item.templateId
                        ? handleCheckExercise
                        : handleCheckTest
                    : undefined
            }
            onCompleteExerciseClick={enableLevelSelect && item.templateId ? handleSubmit : undefined}
            onLevelIconClick={enableLevelSelect && item.templateId ? resetLevel : undefined}
            onNextClick={nextItem}
            onPreviousClick={previousItem}
            onRepeatClick={exerciseResult && item.templateId ? repeatExercise : undefined}
            team1Score={team1Score}
            team2Score={team2Score}
            teamQuestionCount={teamQuestionCount}
        />
    );
};
