import type { ChangeEvent, FC } from "react";
import React, { useCallback } from "react";
import { TextField } from "@material-ui/core";

import type { ExerciseQuestionType } from "../../../types/main";
import { ExerciseBody } from "../../exercise_body";

import "./styles.scss";

type Props = {
    onAnswer: (answer: string, questionId: number) => void;
    question: ExerciseQuestionType;
    questionNumber: number;
    userAnswer?: string;
};

export const QuestionType3: FC<Props> = ({ onAnswer, question, questionNumber, userAnswer }: Props) => {
    const handleAnswerChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => onAnswer(event.target.value, question.id),
        [onAnswer, question.id]
    );

    return (
        <div className="question-type-3">
            {question.instruction && (
                <div
                    className="question-type-3__instruction"
                    dangerouslySetInnerHTML={{ __html: question.instruction }}
                />
            )}
            {question.context && <div dangerouslySetInnerHTML={{ __html: question.context }} />}
            <ExerciseBody number={questionNumber} text={question.text} topic={null} />
            <p>
                <span className="question-type-3__label">Ответ:</span>
                <TextField
                    autoComplete="false"
                    id={`question_${question.id.toString()}`}
                    name={`${new Date().toString()}_question_${question.id.toString()}`}
                    onChange={handleAnswerChange}
                    value={userAnswer ?? ""}
                />
            </p>
        </div>
    );
};
