import type { VideoDataType } from "../types/main";

export const videoData: VideoDataType = {
  poster: "https://demo.brainoom.com/upload/questions/288240_f78f0f9d5aaa.png",
  title: "",
  video: [
    {
      aspectRatio: "1280/720",
      quality: "720p",
      src: "https://demo.brainoom.com/upload/questions/288225_974dab004b92.mp4",
      type: "video/mp4",
    },
  ],
};
