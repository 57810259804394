import type { FC } from "react";
import React, { useCallback, useRef } from "react";
import cx from "classnames";

import { Arrow } from "../../../types/main";
import ExerciseConformityPoint from "./point";

type Props = {
  addArrow: (arrow: Arrow) => void;
  color?: string;
  column: number;
  id: number;
  itemId: string;
  selected?: boolean;
  text: string;
};

const ExerciseConformityItem: FC<Props> = ({
  addArrow,
  color,
  column,
  id,
  itemId,
  selected,
  text,
}: Props) => {
  const startRef = useRef(null);
  const onDragOver = useCallback((e) => e.preventDefault(), []);
  const onDrop = useCallback(
    (e) => {
      if (
        e.dataTransfer.getData("itemId") !== itemId &&
        parseInt(e.dataTransfer.getData("column"), 10) !== column
      ) {
        addArrow({
          end: itemId,
          endId: id,
          start: e.dataTransfer.getData("itemId"),
          startId: parseInt(e.dataTransfer.getData("id"), 10),
        });
      }
    },
    [addArrow, column, id, itemId, selected]
  );
  return (
    <div
      className={cx("exercise-conformity__item", {
        "exercise-conformity__item--selected": selected,
      })}
      id={itemId}
      onDragOver={onDragOver}
      onDrop={onDrop}
      ref={startRef}
    >
      {text.length <= 20 ? (
        <span className="exercise-conformity__item-text--nowrap">{text}</span>
      ) : (
        text
      )}
      <ExerciseConformityPoint
        color={color}
        column={column}
        id={id}
        itemId={itemId}
        startRef={startRef}
      />
    </div>
  );
};

export default React.memo(ExerciseConformityItem);
