import { withStyles } from "@material-ui/core/styles";
import { Slider } from "../slider/slider";

export const SliderProgress = withStyles({
  root: {
    color: "#ff8713",
    height: 6,
    paddingBottom: 5,
    paddingTop: 5,
  },
  thumb: {
    backgroundColor: "#ff8713",
    height: 14,
    marginLeft: -7,
    marginTop: -4,
    width: 14,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    color: "#ff8713",
    height: 6,
  },
  rail: {
    color: "rgba(255, 255, 255, .5)",
    height: 6,
  },
})(Slider);
