import type { FC } from "react";
import React, { useCallback, useMemo, useState } from "react";
import cx from "classnames";

import { Answer } from "../../../types/main";

type Props = {
  answer: Answer;
  correct?: boolean;
  draggable?: boolean;
};

const ExerciseHouseAnswer: FC<Props> = ({
  answer,
  correct,
  draggable = true,
}: Props) => {
  const [position, setPosition] = useState({});
  const style = useMemo(
    () => ({
      ...{
        left: 0,
        top: 0,
      },
      ...position,
    }),
    [position]
  );
  const onDrag = useCallback(
    (e) =>
      setPosition({
        height: 0,
        left: e.clientX,
        opacity: 0,
        position: "fixed",
        top: e.clientY,
        transform: "none",
        width: 0,
      }),
    []
  );
  const onDragEnd = useCallback((e) => setPosition({}), []);
  const onDragStart = useCallback(
    (e) => {
      e.dataTransfer.setData("id", answer.id);
    },
    [answer.id]
  );

  return (
    <div
      className={cx("exercise-house__answer", {
        "exercise-house__answer--correct": correct,
        "exercise-house__answer--wrong": correct === false,
      })}
    >
      {answer.text}
      <div
        className="exercise-house__answer-draggable"
        draggable={draggable}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        style={style}
      />
    </div>
  );
};

export default React.memo(ExerciseHouseAnswer);
