import React, { FC, useCallback } from "react";
import cx from "classnames";

import { Answer, Column } from "../../../types/main";
import ExerciseHouseAnswer from "./answer";

type Props = {
  answers?: Answer[];
  column: Column;
  draggable?: boolean;
  onDrop: (data: { answerId: number; column: number }) => void;
  showAnswers?: boolean;
  success?: boolean;
};

const ExerciseHouseColumn: FC<Props> = ({
  answers,
  column,
  draggable,
  onDrop,
  showAnswers,
  success,
}: Props) => {
  const handleDragOver = useCallback((e) => e.preventDefault(), []);
  const handleDrop: React.DragEventHandler<HTMLDivElement> = useCallback(
    (e) =>
      onDrop({
        answerId: parseInt(e.dataTransfer.getData("id"), 10),
        column: column.column,
      }),
    [column.column, onDrop]
  );
  return (
    <div
      className={cx("exercise-house__column", {
        "exercise-house__column--success": success,
      })}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className="exercise-house__column-title">{column.name}</div>
      {answers?.map((a) => (
        <ExerciseHouseAnswer
          answer={a}
          correct={showAnswers ? column.column === a.column : undefined}
          draggable={draggable}
          key={`column_answer_${a.id}`}
        />
      ))}
      {(answers || []).length === 0 && (
        <div className="exercise-house__column-footer" />
      )}
    </div>
  );
};

export default React.memo(ExerciseHouseColumn);
