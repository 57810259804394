import type { RefObject } from "react";
import { useCallback, useEffect, useState } from "react";

const fullscreenCursorTimeout = 2000;

export const useVideoFullscreen = (
  videoContainer: RefObject<HTMLDivElement>
) => {
  const [fullscreen, setFullscreen] = useState(false);

  const toggleFullscreen = useCallback(() => {
    if (!videoContainer.current) {
      return;
    }
    const requestFullscreen = () => {
      if (videoContainer.current) {
        if (videoContainer.current.requestFullscreen) {
          videoContainer.current.requestFullscreen();
        } else if ((videoContainer.current as any).mozRequestFullScreen) {
          (videoContainer.current as any).mozRequestFullScreen();
        } else if ((videoContainer.current as any).webkitRequestFullScreen) {
          (videoContainer.current as any).webkitRequestFullScreen();
        } else if ((videoContainer.current as any).msRequestFullscreen) {
          (videoContainer.current as any).msRequestFullscreen();
        }
      }
    };

    const exitFullscreen = () => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitCancelFullScreen) {
        (document as any).webkitCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) {
        // Need this to support Safari
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        (document as any).msExitFullscreen();
      }
    };

    if (fullscreen) {
      exitFullscreen();
    } else {
      requestFullscreen();
    }
  }, [fullscreen, videoContainer]);

  useEffect(() => {
    const setFullscreenData = () => {
      const fullscreenElement =
        document.fullscreenElement ||
        (document as any).mozFullScreenElement ||
        (document as any).webkitFullscreenElement;
      setFullscreen(!!fullscreenElement);
    };
    document.addEventListener("fullscreenchange", setFullscreenData);
    document.addEventListener("mozfullscreenchange", setFullscreenData);
    document.addEventListener("webkitfullscreenchange", setFullscreenData);
    document.addEventListener("msfullscreenchange", setFullscreenData);
    return () => {
      document.removeEventListener("fullscreenchange", setFullscreenData);
      document.removeEventListener("mozfullscreenchange", setFullscreenData);
      document.removeEventListener("webkitfullscreenchange", setFullscreenData);
      document.removeEventListener("msfullscreenchange", setFullscreenData);
    };
  }, []);

  // Hide cursor in fullscreen mode after fullscreenCursorTimeout seconds
  useEffect(() => {
    if (!fullscreen) {
      return;
    }
    let t: NodeJS.Timeout | null = null;
    const stopNoCursorTimeout = () => {
      if (t !== null) {
        videoContainer.current?.setAttribute("data-nocursor", "false");
        clearTimeout(t);
        t = null;
      }
    };
    const startNoCursorTimeout = () => {
      t = setTimeout(
        () => videoContainer.current?.setAttribute("data-nocursor", "true"),
        fullscreenCursorTimeout
      );
    };
    const setNoCursor = () => {
      stopNoCursorTimeout();
      startNoCursorTimeout();
    };
    startNoCursorTimeout();
    window.addEventListener("mousemove", setNoCursor);
    return () => {
      window.removeEventListener("mousemove", setNoCursor);
      stopNoCursorTimeout();
    };
  }, [fullscreen, videoContainer]);

  return {
    fullscreen,
    fullscreenEnabled: (document.fullscreenEnabled ||
      (document as any).mozFullScreenEnabled ||
      (document as any).webkitFullscreenEnabled ||
      (document as any).msFullscreenEnabled) as boolean,
    toggleFullscreen,
  };
};
