export const modalMessageTimeout = 900;

export const colors = [
  "#FF6633",
  "#FF33FF",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
  "#FFFF99",
  "#FFB399",
  "#809900",
];

export const successMessages = [
  "Молодец!",
  "Умница!",
  "Верно!",
  "Правильно!",
  "Супер!",
  "Класс!",
  "Точно!",
  "Отлично!",
  "Замечательно!",
  "Да!",
  "Браво!",
  "Ты очень способный!",
  "Великолепно!",
];

export const failMessages = [
  "Подумай ещё!",
  "Промахнулся!",
  "Эх…",
  "Не попал!",
  "Попробуй снова!",
  "Мимо!",
];

export const finishMessages: Record<string, string> = {
  message0: "Начни игру сначала!", // (появляются слова, в другом порядке)
  message10: "Начни игру сначала!", // (появляются слова, в другом порядке)
  message20: "В следующий раз твой результат будет лучше!", // (появляются слова, в которых ошибся ученик)
  message30: "Не торопись, внимательно читай слова!", // (появляются слова, в которых ошибся ученик)
  message40: "Попробуй еще раз сыграть!", // (появляются слова, в которых ошибся ученик)
  message50: "Эх, всего половина верных ответов!", // (появляются слова, в которых ошибся ученик)
  message60: "Будь внимательнее!", // (появляются слова, в которых ошибся ученик)
  message70: "Неплохо!", // (появляются слова, в которых ошибся ученик)
  message80: "Хорошо!", // (появляются слова, в которых ошибся ученик)
  message90: "Молодец!", // (появляется слово, в котором ошибся ученик)
  message100: "Отлично! Ни одной ошибки!",
};
