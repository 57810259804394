import type { ChangeEvent, FC } from "react";
import React, { useCallback } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";

import { ExerciseBody } from "../../exercise_body";
import type { ExerciseQuestionType } from "../../../types/main";

import "./styles.scss";

type Props = {
    onAnswer: (answer: Record<string, boolean>, questionId: number) => void;
    question: ExerciseQuestionType;
    questionNumber: number;
    userAnswers?: Record<string, boolean>;
};

export const QuestionMultiple: FC<Props> = ({ onAnswer, question, questionNumber, userAnswers }: Props) => {
    const handleMultipleAnswer = useCallback(
        (event: ChangeEvent<HTMLInputElement>) =>
            onAnswer(
                {
                    ...(userAnswers || {}),
                    [event.target.name]: event.target.checked,
                },
                question.id
            ),
        [userAnswers, onAnswer, question.id]
    );

    return (
        <div className="question-multiple">
            {question.instruction && (
                <div
                    className="question-multiple__instruction"
                    dangerouslySetInnerHTML={{ __html: question.instruction }}
                />
            )}
            {question.context && <div dangerouslySetInnerHTML={{ __html: question.context }} />}
            <ExerciseBody number={questionNumber} text={question.text} topic={null} />
            <p>Отметьте несколько вариантов.</p>
            <FormGroup>
                {question.answers?.map((a) => (
                    <FormControlLabel
                        key={`answer_${a.id}`}
                        control={
                            <Checkbox
                                checked={userAnswers?.[a.id]}
                                name={a.id.toString()}
                                onChange={handleMultipleAnswer}
                            />
                        }
                        label={<span dangerouslySetInnerHTML={{ __html: a.text }} />}
                    />
                ))}
            </FormGroup>
        </div>
    );
};
