import { useEffect } from "react";

type Props = {
  togglePlay: () => void;
};

export const useVideoHotkeys = ({ togglePlay }: Props) => {
  useEffect(() => {
    const detectKeypress = (e: globalThis.KeyboardEvent) =>
      e.keyCode === 32 ? togglePlay() : undefined;
    window.addEventListener("keydown", detectKeypress);
    return () => window.removeEventListener("keydown", detectKeypress);
  }, [togglePlay]);
};
