import type { FC } from "react";
import React, { useCallback, useLayoutEffect, useRef } from "react";
import Cmi5AU from "react-cmi5";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import { Unit } from "./components/unit";
import { MuiTheme } from "./constants/general";
import { GeneralLayout } from "./layouts/general/layout";

import "./styles/fonts.scss";
import "./styles/general.scss";

const getDemoDataType = (contentId: number) => {
    switch (contentId) {
        case 1:
            return "content";
        case 2:
            return "exercises";
        case 3:
            return "test";
        case 4:
            return "video";
        default:
            return "test";
    }
};
const theme = createMuiTheme(MuiTheme);

export const App: FC = () => {
    const appRef = useRef<HTMLDivElement>(null);
    const sendMessage = useCallback(
        (payload?: Record<string, boolean>) =>
            window.parent.postMessage(
                JSON.stringify({
                    ...payload,
                    documentHeight: appRef.current?.clientHeight,
                }),
                "*"
            ),
        []
    );
    const handleResizeWindow = useCallback(() => sendMessage(), [sendMessage]);
    useLayoutEffect(() => {
        handleResizeWindow();
        window.addEventListener("resize", handleResizeWindow);
        return () => window.removeEventListener("resize", handleResizeWindow);
    }, [handleResizeWindow]);

    const contentId = parseInt(new URL(window.location.href).searchParams.get("contentId") ?? "0", 10);

    const demoDataType = getDemoDataType(contentId);

    return (
        <ThemeProvider theme={theme}>
            <div className="App" ref={appRef}>
                <GeneralLayout>
                    <Cmi5AU>
                        <Unit
                            demoDataType={demoDataType}
                            exerciseColorMode="words"
                            localFile={new URL(window.location.href).searchParams.get("f") || undefined}
                            onChange={sendMessage}
                        />
                    </Cmi5AU>
                </GeneralLayout>
            </div>
        </ThemeProvider>
    );
};
