import React, { FC, PropsWithChildren } from "react";

import "./styles.scss";

type Props = any;

const GeneralLayout: FC<Props> = (props: PropsWithChildren<Props>) => {
  const element = props.children as React.ReactElement;
  return (
    <div className="general-layout">
      <div className="general-layout__content">
        <div className="general-layout__content-container">
          <element.type {...element.props} />
        </div>
      </div>
    </div>
  );
};

export { GeneralLayout };
