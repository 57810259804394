export const errorColors = {
  dark: "#d32f2f",
  light: "#e57373",
  main: "#f44336",
};
export const successColors = {
  dark: "green",
  light: "green",
  main: "green",
};
// $color_Text_price: #ff8713;
// $color_Text_link_hover: #b4d05d;
export const MuiTheme = {
  overrides: {
    // Style sheet name
    MuiButton: {
      // Name of the rule
      containedPrimary: {
        textTransform: "none",
      },
      containedSecondary: {
        textTransform: "none",
      },
      outlined: {
        borderRadius: "18px",
        textTransform: "none",
      },
      root: {
        borderRadius: "18px",
        textTransform: "none",
      },
    },
  },
  palette: {
    primary: {
      contrastText: "#ffffff",
      main: "#ff8713",
    },
    secondary: {
      contrastText: "#ffffff",
      main: "#b4d05d",
    },
    error: errorColors,
  },
  typography: {
    fontFamily:
      // '"open_sans", "Segoe UI", "Helvetica Neue", Helvetica, Arial, FreeSans, "Liberation Sans", "Nimbus Sans L", sans-serif',
      "Gilroy,-apple-system,sans-serif",
    // fontSize: 14,
    // fontWeightLight: 300,
    // fontWeightMedium: 500,
    // fontWeightRegular: 400
  },
} as any;
