import type { FC } from "react";
import React, { useCallback } from "react";
import cx from "classnames";
import { TextField } from "@material-ui/core";

import { ExerciseQuestionType } from "../../../types/main";
import { ExerciseBody } from "../../exercise_body";

import "./styles.scss";

type Props = {
    exercise: ExerciseQuestionType;
    exerciseNumber: number;
    onAnswer: (answer: Record<string, string>, exerciseId: number) => void;
    showAnswers?: boolean;
    userAnswers?: Record<string, string>;
};

export const ExerciseLetter: FC<Props> = ({ exercise, exerciseNumber, onAnswer, showAnswers, userAnswers }: Props) => {
    const handleAnswer = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) =>
            onAnswer(
                {
                    ...(userAnswers || {}),
                    [event.target.id]: event.target.value,
                },
                exercise.id
            ),
        [exercise.id, onAnswer, userAnswers]
    );

    return (
        <div className="exercise-letter">
            <ExerciseBody number={exerciseNumber} text={exercise.text} topic={exercise.topic} />
            <div className="exercise-letter__children">
                {exercise.children?.map((c, i) => (
                    <div className="exercise-letter__row" key={`child_${c.id}`}>
                        <span className="exercise-letter__child-text">
                            {i + 1}. {c.textArray?.[0]}
                        </span>
                        <TextField
                            autoComplete="false"
                            className={cx("exercise-letter__input", {
                                "exercise-letter__input--success":
                                    showAnswers &&
                                    c.answers.find(
                                        (a) =>
                                            a.text.toLowerCase() ===
                                            (userAnswers?.[c.id.toString()]?.toLowerCase() ?? "")
                                    ),
                                "exercise-letter__input--fail":
                                    showAnswers &&
                                    c.answers.every(
                                        (a) =>
                                            a.text.toLowerCase() !==
                                            (userAnswers?.[c.id.toString()]?.toLowerCase() ?? "")
                                    ),
                            })}
                            disabled={showAnswers}
                            id={c.id.toString()}
                            name={new Date().toString() + "_" + c.id.toString()}
                            onChange={handleAnswer}
                            value={userAnswers?.[c.id] ?? ""}
                        />
                        {c.textArray?.[1] && <span className="exercise-letter__child-text">{c.textArray?.[1]}</span>}
                    </div>
                ))}
            </div>
        </div>
    );
};
