import type { FC } from "react";
import React from "react";
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

import type { ExerciseQuestionType } from "../../types/main";
import { ExerciseBody } from "../exercise_body";
import { getNumbersArray } from "./utils/get_numbers_array";
import { getWordsArray } from "./utils/get_words_array";
import { symmetricDifferenceOfArrays } from "./utils/symmetric_difference_of_arrays";

type Props = {
    alwaysDisplay?: boolean;
    answers: Record<string, any>;
    number: number;
    question: ExerciseQuestionType;
};

export const UnitResultItem: FC<Props> = ({ alwaysDisplay, answers, number, question }: Props) => {
    switch (question.typeId) {
        case 1:
        case 2: {
            const userAnswers = (answers[question.id.toString()] ?? {}) as Record<string, boolean>;
            const errors =
                question.answers?.filter(
                    (a) => (!a.correct && userAnswers[a.id.toString()]) || (a.correct && !userAnswers[a.id.toString()])
                ) ?? [];
            return alwaysDisplay || errors.length > 0 ? (
                <div className="unit__question-result">
                    <ExerciseBody
                        key={`question_result_${question.id}`}
                        number={number}
                        text={question.text}
                        topic={null}
                    />
                    <div>
                        <FormGroup>
                            {errors.map((a) => (
                                <FormControlLabel
                                    key={`question_result_answer_${a.id}`}
                                    control={
                                        <Checkbox
                                            checked={userAnswers[a.id.toString()]}
                                            disabled
                                            name={a.id.toString()}
                                        />
                                    }
                                    label={<span dangerouslySetInnerHTML={{ __html: a.text }} />}
                                />
                            ))}
                        </FormGroup>
                    </div>
                    {question.answerDescription && (
                        <div
                            className="unit__question-answer-description"
                            dangerouslySetInnerHTML={{ __html: question.answerDescription }}
                        />
                    )}
                </div>
            ) : null;
        }
        case 3: {
            const userAnswer = (answers[question.id.toString()] ?? "") as string;
            const isSuccess =
                question.answers?.find((a) => a.text.toLowerCase() === userAnswer.trim().toLowerCase()) !== undefined;

            return alwaysDisplay || !isSuccess ? (
                <div className="unit__question-result">
                    <ExerciseBody
                        key={`question_result_${question.id}`}
                        number={number}
                        text={question.text}
                        topic={null}
                    />
                    <div>
                        {userAnswer === "" ? (
                            <span className="error-text">Вы не ответили на вопрос.</span>
                        ) : (
                            <>
                                Ваш ответ: <strong>{userAnswer}</strong>
                            </>
                        )}
                    </div>
                    {question.answers?.length ? (
                        <div>
                            {question.answers.length === 1
                                ? "Правильный ответ:"
                                : "Возможные варианты правильного ответа:"}
                            <ul>
                                {question.answers?.map((a) => (
                                    <li key={`question_result_answer_${a.id}`}>
                                        <span dangerouslySetInnerHTML={{ __html: a.text }} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <div>Правильные ответы не настроены. Приносим свои извинения.</div>
                    )}
                    {question.answerDescription && (
                        <div
                            className="unit__question-answer-description"
                            dangerouslySetInnerHTML={{ __html: question.answerDescription }}
                        />
                    )}
                </div>
            ) : null;
        }
        case 4: {
            const userAnswer = (answers[question.id.toString()] ?? "") as string;
            const userAnswerNumbers = getNumbersArray(userAnswer);

            const isSuccess =
                question.answers?.find(
                    (a) => symmetricDifferenceOfArrays(userAnswerNumbers, getNumbersArray(a.text)).length === 0
                ) !== undefined;

            return alwaysDisplay || !isSuccess ? (
                <div className="unit__question-result">
                    <ExerciseBody
                        key={`question_result_${question.id}`}
                        number={number}
                        text={question.text}
                        topic={null}
                    />
                    <div>
                        {userAnswer === "" ? (
                            <span className="error-text">Вы не ответили на вопрос.</span>
                        ) : (
                            <>
                                Ваш ответ: <strong>{userAnswer}</strong>
                            </>
                        )}
                    </div>
                    {question.answers?.length ? (
                        <div>
                            {question.answers.length === 1
                                ? "Правильный ответ:"
                                : "Возможные варианты правильного ответа:"}
                            <ul>
                                {question.answers?.map((a) => (
                                    <li key={`question_result_answer_${a.id}`}>
                                        <span dangerouslySetInnerHTML={{ __html: a.text }} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <div>Правильные ответы не настроены. Приносим свои извинения.</div>
                    )}
                    {question.answerDescription && (
                        <div
                            className="unit__question-answer-description"
                            dangerouslySetInnerHTML={{ __html: question.answerDescription }}
                        />
                    )}
                </div>
            ) : null;
        }
        case 5: {
            const userAnswers = (answers[question.id.toString()] ?? {}) as Record<string, string>;

            const errors =
                question.answers?.filter((a) => {
                    const userAnswerNumbers = getNumbersArray(userAnswers[a.id.toString()] ?? "");
                    return symmetricDifferenceOfArrays(userAnswerNumbers, getNumbersArray(a.text)).length > 0;
                }) ?? [];

            const questionAnswers = question.answers?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)) ?? [];

            return alwaysDisplay || errors.length > 0 ? (
                <div className="unit__question-result">
                    <ExerciseBody
                        key={`question_result_${question.id}`}
                        number={number}
                        text={question.text}
                        topic={null}
                    />
                    {Object.keys(userAnswers).length === 0 ? (
                        <div className="error-text">Вы не ответили на вопрос.</div>
                    ) : (
                        <div className="unit__test-result__answer">
                            <div className="unit__test-result__label">Ваш ответ:</div>
                            <Table size="small" style={{ width: "auto" }}>
                                <TableHead>
                                    <TableRow>
                                        {questionAnswers.map((a) => (
                                            <TableCell key={`unit__test-result__cell-description__${a.id}`}>
                                                <strong>{a.description}</strong>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {questionAnswers.map((a) => (
                                            <TableCell key={`unit__test-result__cell-answer__${a.id}`}>
                                                {userAnswers?.[a.id]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    )}
                    {question.answers?.length ? (
                        <div className="unit__test-result__answer">
                            <div className="unit__test-result__label">
                                Правильный
                                <br />
                                ответ:
                            </div>
                            <Table size="small" style={{ width: "auto" }}>
                                <TableHead>
                                    <TableRow>
                                        {questionAnswers.map((a) => (
                                            <TableCell key={`unit__test-result__cell-description__${a.id}`}>
                                                <strong>{a.description}</strong>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {questionAnswers.map((a) => (
                                            <TableCell key={`unit__test-result__cell-answer__${a.id}`}>
                                                <span dangerouslySetInnerHTML={{ __html: a.text }} />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    ) : (
                        <div>Правильные ответы не настроены. Приносим свои извинения.</div>
                    )}
                    {question.answerDescription && (
                        <div
                            className="unit__question-answer-description"
                            dangerouslySetInnerHTML={{ __html: question.answerDescription }}
                        />
                    )}
                </div>
            ) : null;
        }
        case 6: {
            const userAnswer = (answers[question.id.toString()] ?? "") as string;
            const userAnswerWords = getWordsArray(userAnswer);

            const isSuccess =
                question.answers?.find(
                    (a) => symmetricDifferenceOfArrays(userAnswerWords, getWordsArray(a.text)).length === 0
                ) !== undefined;

            return alwaysDisplay || !isSuccess ? (
                <div className="unit__question-result">
                    <ExerciseBody
                        key={`question_result_${question.id}`}
                        number={number}
                        text={question.text}
                        topic={null}
                    />
                    <div>
                        {userAnswer === "" ? (
                            <span className="error-text">Вы не ответили на вопрос.</span>
                        ) : (
                            <>
                                Ваш ответ: <strong>{userAnswer}</strong>
                            </>
                        )}
                    </div>
                    {question.answers?.length ? (
                        <div>
                            {question.answers.length === 1
                                ? "Правильный ответ:"
                                : "Возможные варианты правильного ответа:"}
                            <ul>
                                {question.answers?.map((a) => (
                                    <li key={`question_result_answer_${a.id}`}>
                                        <span dangerouslySetInnerHTML={{ __html: a.text }} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <div>Правильные ответы не настроены. Приносим свои извинения.</div>
                    )}
                    {question.answerDescription && (
                        <div
                            className="unit__question-answer-description"
                            dangerouslySetInnerHTML={{ __html: question.answerDescription }}
                        />
                    )}
                </div>
            ) : null;
        }
        default:
            return null;
    }
};
