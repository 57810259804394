import type { FC } from "react";
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import * as _ from "lodash";
import { Button, Modal } from "@material-ui/core";

import { failMessages, finishMessages, modalMessageTimeout, successMessages } from "../../../constants/custom";
import { Answer, ExerciseQuestionType } from "../../../types/main";
import { ExerciseBody } from "../../exercise_body";
import ExerciseTetrisAnswers from "./answers";

import "./styles.scss";

const periodSec = 5.1;
const steps = 20;
const stepWidth = 20;

type Props = {
    exercise: ExerciseQuestionType;
    exerciseNumber: number;
    onAnswer: (answer: Record<string, Answer>, exerciseId: number) => void;
    onBlur: (value: boolean) => void;
    onChange?: () => void;
    userAnswers?: Record<string, Answer>;
};

export const ExerciseTetris: FC<Props> = ({
    exercise,
    exerciseNumber,
    onAnswer,
    onBlur,
    onChange,
    userAnswers,
}: Props) => {
    const [answerMessage, setAnswerMessage] = useState<string | null>(null);
    const [animation, runAnimation] = useState(false);
    const [currentAnswerNumber, setCurrentAnswerNumber] = useState<number | undefined>();
    const [offset, setOffset] = useState<number>(0);
    const timeoutRef = useRef<any>();

    useEffect(() => {
        if (currentAnswerNumber !== undefined && exercise.answers && exercise.answers[currentAnswerNumber]) {
            setOffset(Math.ceil(Math.random() * (steps - (exercise.answers[currentAnswerNumber].text.length ?? 0))));
        }
    }, [currentAnswerNumber, exercise.answers]);

    const runNextAnswer = useCallback(() => {
        if (timeoutRef.current !== undefined) {
            clearTimeout(timeoutRef.current);
        }
        if (currentAnswerNumber === undefined) {
            onAnswer({}, exercise.id);
        }
        setCurrentAnswerNumber((prevState) =>
            prevState !== undefined && exercise.answers?.length ? prevState + 1 : 0
        );
        runAnimation(false);
    }, [currentAnswerNumber, exercise.answers?.length, exercise.id, onAnswer]);

    const restart = useCallback(() => {
        setCurrentAnswerNumber(undefined);
        onAnswer({}, exercise.id);
        runNextAnswer();
    }, [exercise.id, onAnswer, runNextAnswer]);

    const runNextAnswerWithTimeout = useCallback(
        (timeout?: number) => {
            if (timeoutRef.current !== undefined) {
                clearTimeout(timeoutRef.current);
            }
            setTimeout(() => {
                setAnswerMessage(null);
                onBlur(false);
                setCurrentAnswerNumber((prevState) =>
                    prevState !== undefined && exercise.answers?.length ? prevState + 1 : 0
                );
                runAnimation(false);
            }, timeout ?? 0);
        },
        [exercise.answers?.length, onBlur]
    );

    const handleAnswer = useCallback(() => {
        const currentAnswer = currentAnswerNumber !== undefined && exercise.answers?.[currentAnswerNumber];
        if (currentAnswer) {
            onAnswer(
                {
                    ...(userAnswers || {}),
                    [currentAnswer.id.toString()]: currentAnswer,
                },
                exercise.id
            );
            const messages = currentAnswer.correct ? successMessages : failMessages;
            setAnswerMessage(_.shuffle(messages)[0]);
            onBlur(true);
            runNextAnswerWithTimeout(modalMessageTimeout);
        } else {
            runNextAnswer();
        }
    }, [
        currentAnswerNumber,
        exercise.answers,
        exercise.id,
        runNextAnswer,
        runNextAnswerWithTimeout,
        onAnswer,
        onBlur,
        userAnswers,
    ]);

    useLayoutEffect(() => onChange?.(), [currentAnswerNumber, onChange, userAnswers]);

    useEffect(() => {
        if (currentAnswerNumber !== undefined && exercise.answers?.[currentAnswerNumber]) {
            timeoutRef.current = setTimeout(() => runNextAnswer(), periodSec * 1000);
        }
        // Reaction only on currentAnswerNumber
    }, [currentAnswerNumber]);
    useLayoutEffect(() => {
        if (currentAnswerNumber !== undefined && exercise.answers?.[currentAnswerNumber]) {
            setTimeout(() => runAnimation(true), 50);
        }
        // Reaction only on currentAnswerNumber
    }, [currentAnswerNumber]);

    const handleClose = useCallback(() => {
        setAnswerMessage(null);
        onBlur(false);
    }, [onBlur]);

    const userAnswersArray = useMemo(() => (userAnswers ? Object.keys(userAnswers).map((k) => userAnswers[k]) : []), [
        userAnswers,
    ]);

    const skippedAnswers = useMemo(() => {
        const out = [];
        const ids = userAnswers ? Object.keys(userAnswers).map((k) => userAnswers[k].id) : [];
        for (let i = 0; i < (currentAnswerNumber ?? 0); i++) {
            if (exercise.answers && ids.indexOf(exercise.answers[i].id) === -1) {
                out.push(exercise.answers?.[i]);
            }
        }
        return out;
    }, [currentAnswerNumber, exercise.answers, userAnswers]);

    const total = useMemo(() => {
        const le = exercise.answers?.length ?? 0;
        let ka = userAnswers ? Object.keys(userAnswers).filter((k) => userAnswers[k].correct).length : 0;
        ka += skippedAnswers.filter((a) => !a.correct).length;
        const answerPercent = le === 0 ? 100 : Math.round(100 * (ka / le) * 1000) / 1000;
        return Math.ceil(answerPercent);
    }, [exercise.answers, skippedAnswers, userAnswers]);

    return (
        <div className="exercise-tetris">
            <ExerciseBody number={exerciseNumber} text={exercise.text} topic={exercise.topic} />
            {currentAnswerNumber !== undefined ? (
                exercise.answers?.[currentAnswerNumber] ? (
                    <div className="exercise-tetris__game-container">
                        <div className="exercise-tetris__field">
                            {answerMessage == null && (
                                <div
                                    className={cx("exercise-tetris__answer", {
                                        "exercise-tetris__answer--animation": animation,
                                    })}
                                    key={`answer_${exercise.answers?.[currentAnswerNumber].id}`}
                                    onMouseDown={handleAnswer}
                                    style={{ marginLeft: offset * stepWidth }}
                                >
                                    {exercise.answers?.[currentAnswerNumber].text.split("").map((v, i) => (
                                        <div
                                            className="exercise-tetris__letter"
                                            key={`answer_${exercise.answers?.[currentAnswerNumber].id}_${i}`}
                                        >
                                            {v}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <ExerciseTetrisAnswers answers={userAnswersArray} label="Поймано" />
                        <ExerciseTetrisAnswers answers={skippedAnswers} inverse label="Пропущено" />
                        <Modal className="modal-container" open={answerMessage != null} onClose={handleClose}>
                            <div className="modal">{answerMessage}</div>
                        </Modal>
                    </div>
                ) : (
                    <div className="exercise-tetris__result">
                        <h3>Результат: {total} %</h3>
                        <h3>{finishMessages[`message${Math.floor(total / 10) * 10}`]}</h3>
                        <div className="exercise-tetris__result-user-answers">
                            <ExerciseTetrisAnswers answers={userAnswersArray} label="Поймано" />
                            <ExerciseTetrisAnswers answers={skippedAnswers} inverse label="Пропущено" />
                        </div>
                        <div>
                            <Button color="primary" onClick={restart} size="large" variant="contained">
                                Начать сначала
                            </Button>
                        </div>
                    </div>
                )
            ) : (
                <div>
                    <Button color="primary" onClick={runNextAnswer} size="large" variant="contained">
                        Начать
                    </Button>
                </div>
            )}
        </div>
    );
};
