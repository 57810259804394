import type { FC } from "react";
import React from "react";

export const PictureInPictureExitIcon: FC = () => (
  <svg
    aria-hidden="true"
    height={24}
    focusable="false"
    tabIndex={-1}
    viewBox="0 -4 24 32"
    width={24}
  >
    <g>
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fillRule="nonzero"
        d="M21 3a1 1 0 0 1 1 1v7h-2V5H4v14h6v2H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h18zm0 10a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h8zm-9.5-6L9.457 9.043l2.25 2.25-1.414 1.414-2.25-2.25L6 12.5V7h5.5z"
      />
    </g>
  </svg>
);
