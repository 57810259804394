import type { ChangeEvent, FC } from "react";
import React, { useCallback, useMemo } from "react";
import { InputBase, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import type { ExerciseQuestionType } from "../../../types/main";
import { ExerciseBody } from "../../exercise_body";

import "./styles.scss";

type Props = {
    onAnswer: (answer: Record<string, string>, questionId: number) => void;
    question: ExerciseQuestionType;
    questionNumber: number;
    userAnswers?: Record<string, string>;
};

export const QuestionType5: FC<Props> = ({ onAnswer, question, questionNumber, userAnswers }: Props) => {
    const handleAnswerChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const name = event.target.id.replace("answer_", "");
            const value = event.target.value;
            if (/^[0-9]+$/.test(value) || value === "") {
                return onAnswer(
                    {
                        ...(userAnswers ?? {}),
                        [name]: value,
                    },
                    question.id
                );
            }
            return onAnswer(
                {
                    ...(userAnswers ?? {}),
                    [name]: userAnswers?.[name] ?? "",
                },
                question.id
            );
        },
        [onAnswer, question.id, userAnswers]
    );

    const answers = useMemo(() => question.answers?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)) ?? [], [
        question.answers,
    ]);

    return (
        <div className="question-type-5">
            {question.instruction && (
                <div
                    className="question-type-5__instruction"
                    dangerouslySetInnerHTML={{ __html: question.instruction }}
                />
            )}
            {question.context && <div dangerouslySetInnerHTML={{ __html: question.context }} />}
            <ExerciseBody number={questionNumber} text={question.text} topic={null} />
            <div className="question-type-5__answer">
                <div className="question-type-5__label">Ответ:</div>
                <Table size="small" style={{ width: "auto" }}>
                    <TableHead>
                        <TableRow>
                            {answers.map((a) => (
                                <TableCell key={`question-type-5__cell-description__${a.id}`} padding="none">
                                    <strong>{a.description}</strong>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {answers.map((a) => (
                                <TableCell key={`question-type-5__cell-answer__${a.id}`} padding="none">
                                    <InputBase
                                        autoComplete="false"
                                        id={`answer_${a.id.toString()}`}
                                        name={`${new Date().toString()}_answer_${a.id.toString()}`}
                                        onChange={handleAnswerChange}
                                        value={userAnswers?.[a.id] ?? ""}
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};
