import { useEffect } from "react";

import type { Cmi5AUProps } from "../../../types/main";

export const useTerminate = (cmi: Cmi5AUProps["cmi"]) => {
  useEffect(() => {
    let isTerminated = false;
    const beforeUnloadListener = (e: Event) => {
      e.preventDefault();

      if (!cmi || isTerminated) {
        return;
      }

      isTerminated = true;

      const headers: Record<string, string> = {
        Authorization: cmi._lrs.auth,
        "Content-Type": "application/json",
      };
      if (cmi._lrs.version !== "0.9") {
        headers["X-Experience-API-Version"] = cmi._lrs.version;
      }

      const stmt = cmi.terminatedStatement();
      const versionedStatement = stmt.asVersion(cmi.version);
      const fullUrl = `${cmi._lrs.endpoint}statements?statementId=${stmt.id}`;
      fetch(fullUrl, {
        method: stmt.id !== null ? "PUT" : "POST",
        headers,
        body: JSON.stringify(versionedStatement),
        keepalive: true,
      });
    };
    window.addEventListener("beforeunload", beforeUnloadListener);
    window.addEventListener("unload", beforeUnloadListener);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener);
      window.removeEventListener("unload", beforeUnloadListener);
    };
  }, [cmi]);
};
