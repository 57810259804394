import type { FC } from "react";
import React, { useCallback, useMemo, useState } from "react";

type Props = {
  onClick?: (text: string) => void;
  text: string;
};

const ExerciseOrangesAnswer: FC<Props> = ({ onClick, text }: Props) => {
  const [position, setPosition] = useState({});
  const style = useMemo(
    () => ({
      ...{
        left: 0,
        top: 0,
      },
      ...position,
    }),
    [position]
  );
  const onDrag = useCallback(
    (e) =>
      setPosition({
        height: 0,
        left: e.clientX,
        opacity: 0,
        position: "fixed",
        top: e.clientY,
        transform: "none",
        width: 0,
      }),
    []
  );
  const onDragEnd = useCallback((e) => setPosition({}), []);
  const onDragStart = useCallback(
    (e) => {
      e.dataTransfer.setData("text", text);
    },
    [text]
  );

  const handleClick = useCallback(() => onClick?.(text), [onClick, text]);

  return (
    <div className="exercise-oranges__answer">
      {text}
      <div
        className="exercise-oranges__answer-draggable"
        draggable
        onClick={handleClick}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        style={style}
      />
    </div>
  );
};

export default React.memo(ExerciseOrangesAnswer);
