import type { ChangeEvent, FC } from "react";
import React, { useCallback } from "react";
import { FormControlLabel, FormGroup, Radio, RadioGroup } from "@material-ui/core";

import { ExerciseBody } from "../../exercise_body";
import type { ExerciseQuestionType } from "../../../types/main";

import "./styles.scss";

type Props = {
    onAnswer: (answer: Record<string, boolean>, questionId: number) => void;
    question: ExerciseQuestionType;
    questionNumber: number;
    userAnswers?: Record<string, boolean>;
};

export const QuestionSingle: FC<Props> = ({ onAnswer, question, questionNumber, userAnswers }: Props) => {
    const handleSingleAnswer = useCallback(
        (event: ChangeEvent<HTMLInputElement>) =>
            onAnswer(
                {
                    [event.target.value]: true,
                },
                question.id
            ),
        [onAnswer, question.id]
    );

    return (
        <div className="question-single">
            {question.instruction && (
                <div
                    className="question-single__instruction"
                    dangerouslySetInnerHTML={{ __html: question.instruction }}
                />
            )}
            {question.context && <div dangerouslySetInnerHTML={{ __html: question.context }} />}
            <ExerciseBody number={questionNumber} text={question.text} topic={null} />
            <FormGroup>
                <RadioGroup
                    name={question.id.toString()}
                    onChange={handleSingleAnswer}
                    value={userAnswers ? Object.keys(userAnswers).find((k) => userAnswers[k]) : null}
                >
                    {question.answers?.map((a) => (
                        <FormControlLabel
                            key={`answer_${a.id}`}
                            control={<Radio />}
                            label={<span dangerouslySetInnerHTML={{ __html: a.text }} />}
                            value={a.id.toString()}
                        />
                    ))}
                </RadioGroup>
            </FormGroup>
        </div>
    );
};
