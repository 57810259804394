const isIntegerIntersect = (int1: number[], int2: number[]) => int2[1] + 1 >= int1[0] && int1[1] >= int2[0] - 1;

const combine = (int1: number[], int2: number[]) => [Math.min(int1[0], int2[0]), Math.max(int1[1], int2[1])];

export function combineIntersected(intervals: number[][]) {
    const result: number[][] = [];
    let noIntersects = true;
    intervals.forEach((int1) => {
        let combined = false;
        for (let i = 0; i < result.length; i++) {
            const int2 = result[i];
            if (isIntegerIntersect(int1, int2)) {
                result[i] = combine(int1, int2);
                noIntersects = false;
                combined = true;
                break;
            }
        }
        if (!combined) {
            result.push(int1);
        }
    });
    if (noIntersects) {
        return false;
    }
    return result;
}
