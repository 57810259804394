import type { FC } from "react";
import React, { useCallback, useLayoutEffect, useMemo } from "react";
import cx from "classnames";
import { shuffle } from "lodash";
import { Button } from "@material-ui/core";

import { Answer, ExerciseQuestionType } from "../../../types/main";
import { ExerciseBody } from "../../exercise_body";
import ExerciseHouseAnswer from "./answer";
import ExerciseHouseColumn from "./column";

import "./styles.scss";

type Props = {
    exercise: ExerciseQuestionType;
    exerciseNumber: number;
    isGrouped?: boolean;
    onAnswer: (answer: Array<{ answerId: number; column: number }>, exerciseId: number, isCorrect: boolean) => void;
    onChange?: () => void;
    showAnswers?: boolean;
    userAnswers?: Array<{ answerId: number; column: number }>;
};

export const ExerciseHouse: FC<Props> = ({
    exercise,
    exerciseNumber,
    isGrouped,
    onAnswer,
    onChange,
    showAnswers,
    userAnswers,
}: Props) => {
    const handleAnswer = useCallback(
        (data: { answerId: number; column: number }) =>
            !(userAnswers || []).find((v) => v.answerId === data.answerId && v.column === data.column) &&
            onAnswer(
                [...(userAnswers || []).filter((v) => v.answerId !== data.answerId), ...[data]],
                exercise.id,
                exercise.answers?.find((v) => data.answerId === v.id && data.column === v.column) != undefined
            ),
        [onAnswer, exercise.answers, exercise.id, userAnswers]
    );

    const userAnswersMap = useMemo(() => {
        const map = new Set<number>();
        userAnswers?.forEach((v) => {
            map.add(v.answerId);
        });
        return map;
    }, [userAnswers]);

    const answersByColumns = useMemo(() => {
        const map: Record<string, Answer[]> = {};
        const answersMap: Record<string, Answer> = {};
        exercise.answers?.forEach((v) => {
            answersMap[v.id.toString()] = v;
        });
        userAnswers?.forEach((v) => {
            const column = v.column.toString();
            if (!map[column]) {
                map[column] = [];
            }
            map[column].push(answersMap[v.answerId.toString()]);
        });
        return map;
    }, [userAnswers, exercise.answers]);

    const successByColumns = useMemo(() => {
        const map: Record<string, boolean> = {};
        exercise.answers?.forEach((v) => {
            const column = v.column?.toString() ?? "";
            if (map[column] == undefined) {
                map[column] = true;
            }
            map[column] = map[column] && !!userAnswers?.find((ua) => ua.answerId === v.id && ua.column === v.column);
        });
        userAnswers?.forEach((ua) => {
            const column = ua.column.toString();
            if (map[column]) {
                map[column] = !!exercise.answers?.find((v) => ua.answerId === v.id && ua.column === v.column);
            } else {
                map[column] = false;
            }
        });
        return map;
    }, [userAnswers, exercise.answers]);

    const handleStart = useCallback(() => onAnswer([], exercise.id, false), [exercise.id, onAnswer]);

    useLayoutEffect(() => onChange?.(), [onChange, userAnswers]);

    const shuffledAnswers = useMemo(() => shuffle((exercise.answers ?? []).filter((a) => !userAnswersMap.has(a.id))), [
        exercise.answers,
        userAnswersMap,
    ]);

    return (
        <div className="exercise-house">
            <ExerciseBody number={exerciseNumber} text={exercise.text} topic={exercise.topic} />
            <div className="exercise-house__answers">
                {shuffledAnswers.map((a) => (
                    <ExerciseHouseAnswer
                        answer={a}
                        correct={showAnswers ? false : undefined}
                        draggable={!showAnswers}
                        key={`answer_${a.id}`}
                    />
                ))}
            </div>
            <div
                className={cx("exercise-house__columns", {
                    "exercise-house__columns--large": exercise.columns?.every((c) => c.name.length <= 10),
                    "exercise-house__columns--small-standard":
                        exercise.columns?.some((c) => c.name.length > 20) &&
                        exercise.columns?.every((c) => c.name.length <= 30),
                    "exercise-house__columns--small": exercise.columns?.some((c) => c.name.length > 30),
                })}
            >
                {exercise.columns?.map((c) => (
                    <ExerciseHouseColumn
                        answers={answersByColumns[c.column]}
                        column={c}
                        draggable={!isGrouped && !showAnswers}
                        key={`column_${c.column}`}
                        onDrop={handleAnswer}
                        showAnswers={isGrouped || showAnswers}
                        success={showAnswers && successByColumns[c.column]}
                    />
                ))}
            </div>
            {isGrouped && shuffledAnswers.length === 0 && (
                <div>
                    <Button color="primary" onClick={handleStart} size="large" variant="contained">
                        Начать сначала
                    </Button>
                </div>
            )}
        </div>
    );
};
